import * as React from "react"
import styled from "styled-components"
import {AssetProvider} from "./AssetProvider"
import {ActionLink} from "./ActionLink"
// import {ApplicationFooter} from "./AppplicationFooter"
// import '../../src/home/application.scss.erb'

const Header = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 999999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  border: 1px none #000;
  background-color: white;

  @media screen and (max-width: 991px) {
    padding-right: 0vw;
    padding-left: 0vw;
  }
`

const Navigation = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;

  @media screen and (max-width: 991px) {
    width: 100vw;
  }
`

const InnerNavigation = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 96vw;
  min-height: 96px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none hsla(0, 0%, 100%, 0.1);

  @media screen and (max-width: 991px) {
    width: 100%;
    min-height: 72px;
  }

  @media screen and (max-width: 380px) {
    min-height: 64px;
  }

  @media screen and (max-width: 320px) {
    min-height: 48px;
  }
`

const LogoWrapper = styled(ActionLink)`
  display:flex;
  align-items: center;
  text-decoration:none;
  color:black;

  @media screen and (max-width: 767px) {
    padding-left: 10px;
  }
`

const LogoName = styled.div`
  margin-left: 1rem;
  font-family: 'MuseoModerno';
`

const NavigationLogoWrap = styled.div`
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  font-size: 24px;

  & img {
    width: 40px;
  }

  @media screen and (max-width: 991px) {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  @media screen and (max-width: 991px) and (orientation: landscape) {
    padding-left: 16px;
    font-size: 24px;
  }

  @media screen and (max-width: 991px) and (orientation: landscape) {
    padding-left: 16px;
    font-size: 24px;
  }

  @media screen and (max-width: 991px) and (orientation: portrait) {
    padding-left: 16px;
    font-size: 28px;
  }

  @media screen and (max-width: 380px) and (orientation: portrait) {
    font-size: 22px;
    
    & img {
      width: 32px;
    }
  }

  @media screen and (max-width: 320px) {
    font-size: 18px;

    & img {
      width: 24px;
    }
  }
`

class ApplicationHeader extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header>
          <Navigation>
              <InnerNavigation>
                  <NavigationLogoWrap>
                      <LogoWrapper destination="/">
                          <img src={AssetProvider.getAppLogo()} width="45" />
                          <LogoName>Sampld</LogoName>
                      </LogoWrapper>
                  </NavigationLogoWrap>
              </InnerNavigation>
          </Navigation>
        </Header>
      </React.Fragment>
    );
  }
}

export default ApplicationHeader
