import styled from "styled-components"
import * as React from "react"
import ThemedButton, { ThemedButtonSize, ThemedButtonStyle } from "../../global/ThemedButton"
import * as WaveSurfer from "wavesurfer.js"
import {AssetProvider} from "../../global/AssetProvider"

const WaveDiv = styled.div`
  width: calc(100% - 64px);
  height: 240px;
  z-index: 1;
  margin-right: auto;
  margin-left: auto;

  @media only screen and (max-width: 812px) {
    height: 180px;
  }

  @media only screen and (max-width: 768px) and (min-height: 1024px) {
    height: 240px;
  }
`;

const DesktopDownloadButton = styled(ThemedButton)`
  width: 100%;
`

const PlayButtonDiv = styled.button`
  font-family: 'Material Icons';
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: unset;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px;
  z-index: 1;
  color: white;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  -o-user-select: none;
  user-select: none;

  @media only screen and (max-width: 380px) and (orientation: portrait) {
    width: 48px;
    height: 48px;
    margin-top: 16px;
    margin-bottom: 16px;
    font-size:32px;
  }

  @media screen and (max-width: 991px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  @media screen and (max-width: 380px) {
    margin-bottom: 48px;
  }

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    font-size: 32px;
    width: 48px;
    height: 48px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

const SampleWrapper = styled.div `
    display: flex;
    flex-direction: row;
    z-index: 1;
    height: 100%;

    @media only screen and (max-width: 991px) {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
    }

    @media only screen and (max-width: 415px) and (orientation: portrait) {
      flex-direction: column;
      margin-top: 16px;
      margin-bottom: 16px;
    }
`

const SampleDetails = styled.div `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    width: 60vw;
    flex: 2;
    margin: auto;

    @media only screen and (max-width: 415px) and (orientation: portrait) {
      width: calc(100% - 32px);
    }
}
`

const SampleContent = styled.div `
    width: 30vw;
    background: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 12px;

    @media only screen and (max-width: 415px) and (orientation: portrait) {
      width: unset;
      margin: 16px 16px 0;
    }
`

const SampleName = styled.div `
    font-family: "DM Sans", sans-serif;
    font-size: 32px;
    font-weight: 500;
    color: black;
    text-overflow: ellipsis;
    display: contents;

    @media only screen and (max-width: 812px) and (orientation: landscape) {
      font-size: 24px;
    }

    @media only screen and (max-width: 380px) and (orientation: portrait) {
      font-size: 24px;
    }

    @media only screen and (max-width: 320px) and (orientation: portrait) {
      font-size: 20px;
    }
`

const SampleCreatorWrapper = styled.div `
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    margin-bottom: 5px;
`

const SampleCreatorImage = styled.img `
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;

    @media only screen and (max-width: 812px) and (orientation: landscape) {
      width: 20px;
      height: 20px;
    }
`

const SampleCreator = styled.div `
    font-family: "DM Sans", sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: rgba(0,0,0,0.74);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;

    @media only screen and (max-width: 380px) and (orientation: portrait) {
      font-size: 14px;
    }

    @media only screen and (max-width: 320px) and (orientation: portrait) {
      font-size: 12px;
    }
`
const SampleTags = styled.div ``

const TagBadge = styled.div `
    text-transform: uppercase;
    background-color: black;
    color: white;
    border-radius: 25px;
    display: inline-block;
    padding: 5px 8px;
    white-space: nowrap;
    font-size: 12px;
    margin-right: 10px;
    margin-bottom: 10px;
    letter-spacing: 1px;

    @media only screen and (max-width: 812px) and (orientation: landscape) {
      padding: 4px 6px;
      font-size: 8px;
      margin-right: 8px;
      margin-bottom: 8px;
    }

    @media only screen and (max-width: 320px) and (orientation: portrait) {
      font-size: 10px;
    }
`

const TagBadgeMoodDetail = styled.div `
    display: flex;
    flex-direction: row;
    align-items: center;
`

const TagBadgeMoodIcon = styled.img `
    width: 15px;
    height: 15px;
    margin-left: 1px;
    filter: invert(1);

    @media only screen and (max-width: 812px) and (orientation: landscape) {
      width: 10px;
      height: 10px;
    }

    @media only screen and (max-width: 320px) and (orientation: portrait) {
      width: 12px;
      height: 12px;
    }
`

const SampleDescription = styled.p `
    font-size: 15px;
    font-family: 'Roboto';
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    margin-bottom: 0px;

    @media only screen and (max-width: 812px) and (orientation: landscape) {
      font-size: 11px;
    }
`

const SampleDate = styled.p `
    font-size: 12px;
    color: gray;
    margin-top: 0px;
    margin-bottom: 20px;

    @media only screen and (max-width: 812px) and (orientation: landscape) {
      font-size: 9px;
    }
`

const SampleFeatureWrapper = styled.div `
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    @media only screen and (max-width: 568px) and (orientation: landscape) {
      margin-top: 8px;
    }
`

const SampleFeature = styled.div `
    display: block;
    width: 30%;
    margin-right: 20px;
`

const SampleFeatureTitle = styled.p `
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 0;
`

const SampleFeatureContent = styled.span `
    font-size: 30px;
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;

    @media screen and (max-width: 991px) {
      font-size: 20px;
    }
    
    @media screen and (max-width: 380px) {
      font-size: 15px;
    }
`

const SampleContentFooter = styled.div `
    margin-top: auto;
`

const SampleContentFooterDivider = styled.div `
    margin: 16px -16px;
    height: 1px;
    background: rgba(0,0,0,0.2);
`

function kFormatter(num) {
  return Math.abs(num) > 999 ? (Math.sign(num)*(Math.abs(num)/1000)).toFixed(1) + 'k' : Math.sign(num)*Math.abs(num)
}

class WaveformContent extends React.Component<WaveformContentProps, WaveformContentState> {
    constructor(props: WaveformContentProps) {
        super(props)

        this.state = {
            playing: false
        };

    }

    componentDidMount() {
      const progressColor = this.props.progressColor;
      const waveColor = this.props.progressColor + "80";
      const waveformID = "#" + this.props.waveformID;
      
      let waveform = WaveSurfer.create({
        barWidth: 3,
        barRadius: 2,
        cursorWidth: 1,
        container: waveformID,
        backend: "WebAudio",
        height: window.innerWidth > 812 ? 240 : 180,
        progressColor: progressColor,
        responsive: true,
        waveColor: waveColor,
        cursorColor: "transparent",
        hideScrollbar: true
      })

      waveform.on('finish', () => {
        waveform.seekTo(0);
        $("#play-btn").text("play_arrow");
      });

      $.ajax({
        method: "GET",
        url: `/api/samples/${this.props.sampleID}/url/preview`
      })
      .done(function(response) {
        waveform.load(response.url);
      });

      this.setState({waveform: waveform})
    }

    handleSeek = () => {
      this.setState({ playing: false });
    }

    handlePlay = () => {
      this.setState({ playing: !this.state.playing });
      if (this.state.playing) {
          this.state.waveform.pause();
      } else {
          this.state.waveform.play();
      }
    }

    downloadButtonClicked(e: React.MouseEvent<HTMLButtonElement>) {
      $('html, body').animate({
          scrollTop: $("#download").offset().top
      }, 600)
    }
    
    render() {
      return (
          <React.Fragment>
            <SampleWrapper>
              <SampleDetails>
                <WaveDiv id={this.props.waveformID} onClick={this.handleSeek}/>
                <PlayButtonDiv id="play-btn" onClick={this.handlePlay} style={{background: this.props.progressColor}} className="mdc-elevation--z2">
                  {!this.state.playing ? "play_arrow" : "pause"}
                </PlayButtonDiv>
              </SampleDetails>
              <SampleContent className="mdc-elevation--z4">
                <SampleName>{this.props.sampleName}</SampleName>
                <SampleCreatorWrapper>
                  <SampleCreatorImage src={this.props.sampleCreatorImage}/>
                  <SampleCreator>{this.props.sampleCreator}</SampleCreator>
                </SampleCreatorWrapper>

                <SampleDescription>{this.props.sampleDescription}</SampleDescription>
                <SampleDate>Uploaded {this.props.sampleCreatedAt} · {kFormatter(this.props.sampleDownloadCount)} downloads</SampleDate>

                <SampleTags>
                  <TagBadge style={{backgroundColor: this.props.progressColor}}>
                    <TagBadgeMoodDetail>
                      {this.props.sampleMood}
                      {AssetProvider.getMoodIcon(this.props.sampleMood).length > 0 && 
                        <TagBadgeMoodIcon src={AssetProvider.getMoodIcon(this.props.sampleMood)}/>
                      }
                    </TagBadgeMoodDetail>
                  </TagBadge>
                  {this.props.sampleGenres.map((genre) => 
                    <TagBadge key={genre}>{genre}</TagBadge>
                  )}
                  {this.props.sampleTags.map((tag) =>
                    <TagBadge key={tag}>{tag}</TagBadge>
                  )}
                </SampleTags>

                <SampleFeatureWrapper>
                  <SampleFeature>
                    <SampleFeatureTitle>KEY</SampleFeatureTitle>
                    <SampleFeatureContent>{this.props.sampleKey}</SampleFeatureContent>
                  </SampleFeature>
                  <SampleFeature>
                    <SampleFeatureTitle>BPM</SampleFeatureTitle>
                    <SampleFeatureContent>{this.props.sampleBPM}</SampleFeatureContent>
                  </SampleFeature>
                </SampleFeatureWrapper>

                <SampleContentFooter>
                  <SampleContentFooterDivider/>
                  <DesktopDownloadButton label="DOWNLOAD" buttonStyle={ThemedButtonStyle.NormalInverse}
                    size={ThemedButtonSize.Normal} onClick={this.downloadButtonClicked} />
                </SampleContentFooter>
              </SampleContent>
            </SampleWrapper>
          </React.Fragment>
      )
    }
}

interface WaveformContentProps {
    waveformID: string
    progressColor: string
    sampleID: string
    sampleName: string
    sampleCreator: string
    sampleCreatorImage: string
    sampleMood: string
    sampleGenres: string[]
    sampleTags: string[]
    sampleDescription: string
    sampleCreatedAt: string
    sampleDownloadCount: number
    sampleKey: string
    sampleBPM: number
};

interface WaveformContentState {
    waveform?: WaveSurfer
    playing: boolean
}

export default WaveformContent