import React from "react"
import PropTypes from "prop-types"
import ApplicationHeader from "./global/ApplicationHeader"
import ApplicationFooter from "./global/ApplicationFooter"
import WaveformContainer from "./sample/WaveformContainer"
import RegisterContainer from "./sample/RegisterContainer"
import styled from "styled-components"
import "./global/MasterTheme.scss"

const Content = styled.div`
  width: 100%;
  height: 100%;
`

class ShareSample extends React.Component {
  render () {
    return (
      <React.Fragment>
        <ApplicationHeader/>
        <Content>
          <WaveformContainer waveColor={this.props.waveColor} progressColor={this.props.progressColor} sampleID={this.props.sampleID}
            sampleCreator={this.props.sampleCreator} sampleName={this.props.sampleName} sampleCreatorImage={this.props.sampleCreatorImage}
            sampleMood={this.props.sampleMood} sampleGenres={this.props.sampleGenres} sampleTags={this.props.sampleTags}
            sampleDescription={this.props.sampleDescription} sampleCreatedAt={this.props.sampleCreatedAt} 
            sampleDownloadCount={this.props.sampleDownloadCount} sampleKey={this.props.sampleKey} sampleBPM={this.props.sampleBPM}
          />
          <RegisterContainer/>
        </Content>
        <ApplicationFooter/>
      </React.Fragment>
    );
  }
}

ShareSample.propTypes = {
  waveColor: PropTypes.string,
  progressColor: PropTypes.string,
  sampleName: PropTypes.string,
  sampleCreator: PropTypes.string,
  sampleCreatorImage: PropTypes.string,
  sampleMood: PropTypes.string,
  sampleGenres: PropTypes.array,
  sampleTags: PropTypes.array,
  sampleDescription: PropTypes.string,
  sampleCreatedAt: PropTypes.string,
  sampleDownloadCount: PropTypes.number,
  sampleKey: PropTypes.string,
  sampleBPM: PropTypes.number,
  sampleID: PropTypes.string
};

export default ShareSample