import "./List.scss"
import * as React from "react"
import {MDCList} from '@material/list';
import {MDCRipple} from '@material/ripple';

interface ListProps {
    className?: string
    items: {label: string, selected: boolean, action: () => void }[]
}

class MaterialList extends React.Component<ListProps> {
    componentDidMount() {
        const list = new MDCList(document.querySelector('.mdc-deprecated-list'));
        const listItemRipples = list.listElements.map((listItemEl) => MDCRipple.attachTo(listItemEl));
    }

    render() {
        return (
            <React.Fragment>
                <ul className={`mdc-deprecated-list ${this.props.className}`}>
                    {this.props.items.map((item, i) => {
                        return (
                            <li className={`mdc-deprecated-list-item ${item.selected ? "mdc-deprecated-list-item--selected" : ""}`} 
                                tabIndex={i} onClick={item.action}>
                                <span className="mdc-deprecated-list-item__ripple"></span>
                                <span className="mdc-deprecated-list-item__text">{item.label}</span>
                            </li>
                        )
                    })}
                </ul>
            </React.Fragment>
        )
    }
}

export default MaterialList
