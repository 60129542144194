import * as React from "react"
import styled from "styled-components"
import { AssetProvider } from "./AssetProvider";

const FooterContainer = styled.div`
  text-align: center;
  padding-bottom: 30px;
  background: white;
`

const FooterLink = styled.a`
  margin-right: 15px;
  margin-left: 15px;
  color: #6b6b6b;
  font-size: 12px;
  text-decoration: none;

  & :hover {
    color: #fff;
  }
`

const FooterLinkList = styled.div`
  padding-bottom: 29px;

  & img {
    width: 24px;
  }

  @media screen and (max-width: 991px) {
    & img {
      width: 32px;
    }
  }

  @media screen and (max-width: 767px) {
    display: inline-block;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 380px) {
    & img {
      width: 24px;
    }
  }
`

const FooterText = styled.div`
  padding: 40px 0 20px;
  font-size: 18px;

  @media screen and (max-width: 991px) {
    padding: 32px 0 16px;
    font-size: 20px;
  }

  @media screen and (max-width: 380px) {
    font-size: 14px;
  }
`

const FooterCredit = styled.div`
  font-size: 14px;
  opacity: 0.6;

  @media screen and (max-width: 991px) {
    font-size: 16px;
    opacity: 0.6;
  }

  @media screen and (max-width: 380px) {
    font-size: 12px;
  }
`

class ApplicationFooter extends React.Component {
  render () {
    return (
      <React.Fragment>
        <FooterContainer>
          <FooterText>Find us on</FooterText>
          <FooterLinkList>
              <FooterLink id="send-mail-icon-btn" href="mailto:info@sampld.app" target="_blank"><img src={AssetProvider.getEmailIcon()} /></FooterLink>
              <FooterLink id="ig-icon-btn" href="https://www.instagram.com/sampldapp" target="_blank"><img src={AssetProvider.getInstagramIcon()} /></FooterLink>
              <FooterLink id="fb-icon-btn" href="https://www.facebook.com/sampldapp" target="_blank"><img src={AssetProvider.getFacebookIcon()} /></FooterLink>
              <FooterLink id="yt-icon-btn" href="https://www.youtube.com/c/thechordgenius" target="_blank"><img src={AssetProvider.getYouTubeIcon()} /></FooterLink>
          </FooterLinkList>
          <FooterCredit>PT Chorus Digital Indonesia © {new Date().getFullYear()}</FooterCredit>
      </FooterContainer>
      </React.Fragment>
    );
  }
}

export default ApplicationFooter
