import * as React from "react"
import "../../src/home/sample_sharing.js"
import "./RegisterContainer.scss"
import styled from "styled-components"
import {AssetProvider} from "../global/AssetProvider"
import {ThemedButton, ThemedButtonSize, ThemedButtonStyle} from "../global/ThemedButton"
import ModalDialog from "../global/ModalDialog"
import PopupDialog from "../global/PopupDialog"

const CallToActionBackground = styled.div `
    background-image: url("${AssetProvider.getDownloadSampleBackground()}");
    background-position: 50% 50%, 0px 0px;
    background-size: cover, auto;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.6;
    top: 0;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: unset;

    @media screen and (max-width: 991px) and (orientation: portrait) {
        display: block;

        & >div {
            height: 36vh;
        }
    }

    @media screen and (max-width: 380px) and (orientation: portrait) {
        & >div {
            height: 40vh;
        }
    }

    @media screen and (max-width: 320px) and (orientation: portrait) {
        & >div {
            height: 48vh;
        }
    }
`

const CallToActionContainer = styled.div`
    flex: 1;
    padding: 15vh 0;
    background-color: black;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 991px) and (orientation: portrait) {
        padding: unset;
    }
`

const CallToActionTextContainer = styled.div`
    max-width: unset;
    padding: 20px 20px;
    text-align: center;
    z-index: 1;

    @media screen and (max-width: 767px) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        max-width: none;
        padding-right: 15vw;
        padding-left: 15vw;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    
    @media screen and (max-width: 479px) {
        padding-right: 5vw;
        padding-left: 5vw;
    }
`

const CallToActionText = styled.h2`
    margin-bottom: 30px;
    color: #fff;
    font-size: 32px;
    line-height: 40px;
    font-weight: 300;
    font-family: 'DM Sans';

    @media screen and (max-width: 991px) and (orientation: landscape) {
        font-size: 32px;
        line-height: 40px;
    }

    @media screen and (max-width: 991px) and (orientation: portrait) {
        font-size: 32px;
        line-height: 40px;
    }

    @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 32px;
    }

    @media screen and (max-width: 320px) {
        font-size: 20px;
        line-height: 28px;
    }
`

const AppName = styled.div`
    display: inline;
    font-family: 'MuseoModerno';
`

const DetailContainer = styled.div`
    flex: 1;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
`

const DetailTitle = styled.div`
    font-size: 24px;
    line-height: 3rem;
    text-align: center;

    @media screen and (max-width: 991px) and (orientation: portrait) {
        font-size: 24px;
        line-height: 2rem;
    }

    @media screen and (max-width: 380px) and (orientation: portrait) {
        font-size: 22px;
        line-height: 2rem;
    }

    @media screen and (max-width: 320px) and (orientation: portrait) {
        font-size: 20px;
        line-height: 1.8rem;
    }
`

const DetailSubtitle = styled.div`
    font-size: 16px;
    text-align: center;
    margin: 0 4rem;

    @media screen and (max-width: 991px) and (orientation: portrait) {
        font-size: 18px;
    }

    @media screen and (max-width: 380px) and (orientation: portrait) {
        font-size: 16px;
    }

    @media screen and (max-width: 320px) and (orientation: portrait) {
        font-size: 12px;
    }
`

const DetailButtonsContainer = styled.div`
    display: flex;
    padding: 2rem 2rem 0;

    & button+button {
        margin-left: 1rem;
    }

    @media screen and (max-width: 991px) and (orientation: portrait) {
        // padding: 4rem;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & button+button {
            margin-left: unset;
            margin-top: 1rem;
        }
    }
`

const RegisterContentDescription = styled.div`
    padding: 16px 0 24px;

    @media only screen and (max-width: 991px) and (orientation: portrait) {
        font-size: 18px;
        line-height: normal;
    }

    @media only screen and (max-width: 479px) and (orientation: portrait) {
        font-size: 14px;
        line-height: normal;
        padding: 8px 0;
    }
`

const RegisterContentFieldsContainer = styled.div`
    @media only screen and (max-width: 991px) and (orientation: portrait) {
        margin: 16px 0;
    }
`

const RegisterContentFieldsContainerField = styled.div`
    flex: 1;

    & + & {
        margin-top: 16px;
    }

    @media only screen and (max-width: 991px) and (orientation: portrait) {
        width: 100%;

        & + & {
            margin-left: 0;
        }
    }
`

const RegisterContainerFieldTextField = styled.label`
    width: 100%;
`

const TextFieldInput = styled.input``
const FloatingLabel = styled.span ``

const TextFieldHelperLine = styled.div``
const TextFieldHelperText = styled.div``
const TextFieldRipple = styled.span ``

class RegisterContainer extends React.Component {
    render () {
        return (
        <React.Fragment>
            <Container id="download">
                <CallToActionContainer>
                    <CallToActionBackground/>
                    <CallToActionTextContainer>
                        <CallToActionText>
                            Download this sample now using the <AppName>Sampld</AppName> app.
                        </CallToActionText>
                    </CallToActionTextContainer>
                </CallToActionContainer>
                <DetailContainer>
                    <DetailTitle><AppName>Sampld</AppName> is in beta.</DetailTitle>
                    <DetailSubtitle>Try now on Android or iOS and send us your feedback.</DetailSubtitle>
                    <DetailButtonsContainer>
                        <ThemedButton id="download-android-btn" label="Download for Android" icon="android" 
                            size={ThemedButtonSize.Normal} buttonStyle={ThemedButtonStyle.Outlined} />
                            <ThemedButton id="download-ios-btn" label="Download for iOS" 
                                iconAsset={AssetProvider.getAppleIcon()} size={ThemedButtonSize.Normal} 
                                buttonStyle={ThemedButtonStyle.Normal} />
                    </DetailButtonsContainer>
                </DetailContainer>
            </Container>

            <ModalDialog title="Register for beta" dialogClass="mdc-dialog--register" logoURL={AssetProvider.getAppLogoWhite()} id="register-dialog"
                titleID="register-dialog__title" backgroundImageURL={AssetProvider.getRegisterImageBackground()} dataMdcDialogAction="submit" buttonLabel="SUBMIT"
                withLoading={true}>

                <RegisterContentDescription className="mdc-dialog__content__description">Enter your name and Play Store email below and we'll send you the registration link to download the app</RegisterContentDescription>
                <RegisterContentFieldsContainer className="mdc-dialog__content__fields-container">
                    <RegisterContentFieldsContainerField className="mdc-dialog__content__fields-container__field">
                        <RegisterContainerFieldTextField className="mdc-text-field mdc-text-field--filled mdc-text-field--no-label" id="name-field">
                            <TextFieldRipple className="mdc-text-field__ripple"/>
                            <FloatingLabel id="name-field__label" className="mdc-floating-label">Name</FloatingLabel>
                            <TextFieldInput type="text" className="mdc-text-field__input" aria-labelledby="name-field__label" required minLength={3} maxLength={30} placeholder="Name"/>
                        </RegisterContainerFieldTextField>

                        <TextFieldHelperLine className="mdc-text-field-helper-line">
                            <TextFieldHelperText className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="name-field__helper" aria-hidden="true"/>
                        </TextFieldHelperLine>
                    </RegisterContentFieldsContainerField>

                    <RegisterContentFieldsContainerField className="mdc-dialog__content__fields-container__field">
                        <RegisterContainerFieldTextField className="mdc-text-field mdc-text-field--filled mdc-text-field--no-label" id="email-field">
                            <TextFieldRipple className="mdc-text-field__ripple"/>
                            <FloatingLabel id="email-field__label" className="mdc-floating-label">Email</FloatingLabel>
                            <TextFieldInput type="email" className="mdc-text-field__input" aria-labelledby="email-field__label" required pattern="^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$" placeholder="Email"/>
                        </RegisterContainerFieldTextField>
                        <TextFieldHelperLine className="mdc-text-field-helper-line">
                            <TextFieldHelperText className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="email-field__helper" aria-hidden="true"/>
                        </TextFieldHelperLine>
                    </RegisterContentFieldsContainerField>
                </RegisterContentFieldsContainer>
            </ModalDialog>

            <PopupDialog type="confirmation" title="Registration link sent" content="Check your inbox and open an email from Testflight for instructions to download the app."/>
            <PopupDialog type="error" title="Unknown Error Occured" content="Please try again in a few minutes."/>

            {/* <div id="register-dialog" className="mdc-dialog mdc-dialog--register">
                <div className="mdc-dialog__container">
                    <div className="mdc-dialog__surface" role="alertdialog" aria-modal="true" aria-labelledby="my-dialog-title"
                    aria-describedby="register-dialog__title">
                        <h2 className="mdc-dialog__title" id="register-dialog__title">
                            <span>Register for beta</span>
                            <button className="mdc-dialog__close mdc-icon-button material-icons">close</button>
                        </h2>
                        <div className="mdc-dialog__content">
                            <img src={AssetProvider.getRegisterImage()}/>
                            <div className="mdc-dialog__content__description">Enter your name and Play Store email below and we'll send you the registration link to download the app.</div>
                            <div className="mdc-dialog__content__fields-container">
                                <div className="mdc-dialog__content__fields-container__field">
                                    <label id="name-field" className="mdc-text-field mdc-text-field--outlined">
                                        <input type="text" className="mdc-text-field__input" aria-labelledby="name-field__label" 
                                            required minLength={3} maxLength={30}/>
                                        <span className="mdc-notched-outline">
                                            <span className="mdc-notched-outline__leading"></span>
                                            <span className="mdc-notched-outline__notch">
                                            <span className="mdc-floating-label" id="name-field__label">Name</span>
                                            </span>
                                            <span className="mdc-notched-outline__trailing"></span>
                                        </span>
                                    </label>
                                    <div className="mdc-text-field-helper-line">
                                        <div className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="name-field__helper" aria-hidden="true"></div>
                                    </div>
                                </div>
                                <div className="mdc-dialog__content__fields-container__field">
                                    <label id="email-field" className="mdc-text-field mdc-text-field--outlined">
                                        <input type="email" className="mdc-text-field__input" aria-labelledby="email-field__label"
                                            required pattern="^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$"/>
                                        <span className="mdc-notched-outline">
                                            <span className="mdc-notched-outline__leading"></span>
                                            <span className="mdc-notched-outline__notch">
                                                <span className="mdc-floating-label" id="email-field__label">Play Store Email</span>
                                            </span>
                                            <span className="mdc-notched-outline__trailing"></span>
                                        </span>
                                    </label>
                                    <div className="mdc-text-field-helper-line">
                                        <div className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="email-field__helper" aria-hidden="true"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mdc-dialog__actions">
                            <button className="mdc-button mdc-button--black mdc-button--submit" data-mdc-dialog-action="submit">
                                <div className="mdc-button__ripple"></div>
                                <span className="mdc-button__label">SUBMIT</span>
                            </button>
                            <img src={AssetProvider.getLoadingGIF()} className= "mdc-dialog__loading mdc-dialog__loading--hidden"/>
                        </div>
                    </div>
                </div>
                <div className="mdc-dialog__scrim"></div>
            </div>
            <div id="confirmation-dialog" className="mdc-dialog">
                <div className="mdc-dialog__container">
                    <div className="mdc-dialog__surface" role="alertdialog" aria-modal="true" aria-labelledby="my-dialog-title"
                    aria-describedby="register-dialog__title">
                        <h2 className="mdc-dialog__title" id="register-dialog__title">Registration link sent</h2>
                        <div className="mdc-dialog__content">Check your inbox and open an email from Testflight for instructions to download the app.</div>
                        <div className="mdc-dialog__actions">
                            <button className="mdc-button mdc-button--black mdc-button--submit" data-mdc-dialog-action="done">
                                <div className="mdc-button__ripple"></div>
                                <span className="mdc-button__label">OK</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="mdc-dialog__scrim"></div>
            </div>
            <div id="error-dialog" className="mdc-dialog">
                <div className="mdc-dialog__container">
                    <div className="mdc-dialog__surface" role="alertdialog" aria-modal="true" aria-labelledby="my-dialog-title"
                    aria-describedby="register-dialog__title">
                        <h2 className="mdc-dialog__title" id="register-dialog__title">Unknown error occurred</h2>
                        <div className="mdc-dialog__content">Please try again in a few minutes.</div>
                        <div className="mdc-dialog__actions">
                            <button className="mdc-button mdc-button--black mdc-button--submit" data-mdc-dialog-action="done">
                                <div className="mdc-button__ripple"></div>
                                <span className="mdc-button__label">OK</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="mdc-dialog__scrim"></div>
            </div> */}
        </React.Fragment>
        );
    }
}

export default RegisterContainer
