import React from "react"
import PropTypes from "prop-types"
import WaveformContent from "./styling/WaveformContent"
import SampleBackground from "./styling/SampleBackground.tsx"
import styled from "styled-components"

const Waveform = styled.div `
  display: flex;
  flex-direction: column;
  height: calc(100vh - 96px);
  margin-top: 96px;
  padding: 32px;
  position: relative;
  box-sizing: border-box;

  @media screen and (max-height: 500px) {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media only screen and (max-width: 1024px) and (max-height: 768px) and (orientation: landscape) {
    height: calc(80vh - 96px);
  }

  @media only screen and (min-width: 1366px) and (min-height: 1024px) and (orientation: landscape) {
    height: calc(80vh - 96px);
  }

  @media only screen and (max-width: 1024px) and (min-height: 1366px) and (orientation: portrait) {
    height: calc(50vh - 96px);
  }

  @media screen and (max-width: 991px) {
    height: 100%;
    margin-top: 72px;
  }
  
  @media screen and (max-width: 380px) {
    margin-top: 64px;
  }
  
  @media screen and (max-width: 320px) {
    margin-top: 48px;
  }

  @media screen and (max-width: 415px) and (orientation: portrait) {
    padding: 0px;
    align-items: center;
    width: 100%;
  }
`;

class WaveformContainer extends React.Component {
  render () {
    return (
      <React.Fragment>
        <SampleBackground backgroundColorPrimary={this.props.progressColor} backgroundColorSecondary={this.props.waveColor}/>
        <Waveform>
          <WaveformContent progressColor={this.props.progressColor} sampleID={this.props.sampleID}
            sampleCreator={this.props.sampleCreator} sampleName={this.props.sampleName} sampleCreatorImage={this.props.sampleCreatorImage} 
            sampleMood={this.props.sampleMood} sampleGenres={this.props.sampleGenres} sampleTags={this.props.sampleTags}
            sampleDescription={this.props.sampleDescription} sampleCreatedAt={this.props.sampleCreatedAt} 
            sampleDownloadCount={this.props.sampleDownloadCount} sampleKey={this.props.sampleKey} sampleBPM={this.props.sampleBPM}
            waveformID="waveform"/>
        </Waveform>
      </React.Fragment>
    );
  }
}

WaveformContainer.propTypes = {
  waveColor: PropTypes.string,
  progressColor: PropTypes.string,
  sampleName: PropTypes.string,
  sampleCreator: PropTypes.string,
  sampleCreatorImage: PropTypes.string,
  sampleMood: PropTypes.string,
  sampleGenres: PropTypes.array,
  sampleTags: PropTypes.array,
  sampleDescription: PropTypes.string,
  sampleCreatedAt: PropTypes.string,
  sampleDownloadCount: PropTypes.number,
  sampleKey: PropTypes.string,
  sampleBPM: PropTypes.number,
  sampleID: PropTypes.string
};

export default WaveformContainer
