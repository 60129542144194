import * as React from "react"
import ApplicationHeader from "../global/ApplicationHeader"
import ApplicationFooter from "../global/ApplicationFooter"
import styled from "styled-components"
import "../global/MasterTheme.scss"
import "./LegalPage.scss"
import MaterialList from "../global/material/List"

const PageContent = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 96px;
  box-sizing: border-box;
  display: flex;

  @media screen and (max-width: 991px) {
    width: 100%;
    margin-top: 72px;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }

  @media screen and (max-width: 380px) {
    margin-top: 64px;
  }

  @media screen and (max-width: 320px) {
    margin-top: 48px;
  }
`

const Content = styled.div`
  flex: 4;
  padding: 0 16px;
`

const ContentTitle = styled.h1`
`

const Sidebar = styled(MaterialList)`
  flex: 1;
  margin-left: 16px;
  box-sizing: border-box;

  @media only screen and (max-width: 767px) and (orientation: portrait) {
    margin-right: 16px;
  }
`

const LicenseList = styled.ul`
  padding-left: 0;
`

const LicenseListItemAllowed = styled.li`
  list-style: none;
  margin-bottom: 4px;

  &:before {
    display: inline-flex;
    font-family: "Material Icons";
    font-size: 16px;
    content: "check_circle";
    color: #4CAF50;
    margin-right: 8px;
    vertical-align: middle;
    padding-bottom: 4px;
  }
`

const LicenseListItemNotPermitted = styled.li`
  list-style: none;
  margin-bottom: 4px;

  &:before {
    display: inline-flex;
    font-family: "Material Icons";
    font-size: 16px;
    content: "remove_circle";
    color: #F44336;
    margin-right: 8px;
    vertical-align: middle;
    padding-bottom: 4px;
  }
`

const Longform = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6;
`

const FooterDivider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 32px;
  background-color: rgba(0,0,0,0.2);
`

interface LegalPageProps {
  page: string
}

interface LegalPageState {
  page: string
}

class LegalPage extends React.Component<LegalPageProps, LegalPageState> {
  constructor(props: LegalPageProps) {
    super(props);
    this.state = {page: props.page};
  }

  render () {
    return (
      <React.Fragment>
        <ApplicationHeader/>
        <PageContent>
          <Sidebar items={this.listItems(this.state.page)} />
          {this.pageContent(this.state.page)}
        </PageContent>
        <FooterDivider/>
        <ApplicationFooter/>
      </React.Fragment>
    );
  }

  listItems = (selectedItem: string) => { 
    return [
      {
        label: "License",
        selected: selectedItem == "license",
        action: () => {
          document.title = "License | Sampld"
          window.history.pushState("", "License | Sampld", "/license");
          this.setState({page: "license"})
        }
      },
      {
        label: "Privacy Policy",
        selected: selectedItem == "privacy",
        action: () => {
          document.title = "Privacy Policy | Sampld"
          window.history.pushState("", "Privacy Policy | Sampld", "/privacy-policy");
          this.setState({page: "privacy"})
        }
      },
      {
        label: "Terms & Conditions",
        selected: selectedItem == "terms",
        action: () => {
          document.title = "Terms & Conditions | Sampld"
          window.history.pushState("", "Terms & Conditions | Sampld", "/terms-conditions");
          this.setState({page: "terms"})
        }
      },
      {
        label: "Cookie Policy",
        selected: selectedItem == "cookie",
        action: () => {
          document.title = "Cookie Policy | Sampld"
          window.history.pushState("", "Cookie Policy | Sampld", "/cookie-policy");
          this.setState({page: "cookie"})
        }
      },
      {
        label: "Refund Policy",
        selected: selectedItem == "refund",
        action: () => {
          document.title = "Refund Policy | Sampld"
          window.history.pushState("", "Refund Policy | Sampld", "/refund-policy");
          this.setState({page: "refund"})
        }
      }
    ]
  }

  pageContent = (page: string) => {
    switch (page) {
      case "license":
        return (
          <Content>
            <ContentTitle>License</ContentTitle>
            <div>Sampld sounds are made to be used freely.</div>
            <LicenseList>
              <LicenseListItemAllowed>All sounds can be <strong>downloaded</strong> and <strong>used</strong> for <strong>free</strong></LicenseListItemAllowed>
              <LicenseListItemAllowed>Both <b>commercial</b> and <b>non-commercial</b> use of licensed material are allowed</LicenseListItemAllowed>
              <LicenseListItemAllowed>No permission needed, though an attribution would be welcome</LicenseListItemAllowed>
            </LicenseList>
            <h4>What is not permitted:</h4>
            <LicenseList>
              <LicenseListItemNotPermitted>Compiling sounds (by itself or with modification) from Sampld to create packs, or to replicate a similar or competing service.</LicenseListItemNotPermitted>
              <LicenseListItemNotPermitted>Sounds may not be placed in a complete or archived downloadable format in any (but not limited to) website or other mobile app.</LicenseListItemNotPermitted>
            </LicenseList>
            <h4>Longform</h4>
            <Longform>
            Sampld grants you an irrevocable, nonexclusive, worldwide copyright license to download, copy, modify, distribute, perform, and use sounds from Sampld for free, 
            including for commercial purposes, without permission from or attributing the content owner or Sampld. This license does not include the right to compile sounds 
            from Sampld to replicate a similar or competing service.
            </Longform>
          </Content>
        );
      case "privacy":
        return (
          <Content>
            <ContentTitle>Privacy Policy</ContentTitle>
            <Longform>Effective date: 1st August 2021</Longform>
            <Longform>
              The Sampld Site <a href="https://sampld.app" target="_blank">sampld.app</a>, the web 
              app <a href="https://open.sampld.app" target="_blank">open.sampld.app</a> (the “Site”) 
              and the Sampld Mobile Application (the “App”) is 
              owned by <strong>PT Chorus Digital Indonesia</strong> 
              (“Sampld”, “we”, “us”, “Sampld” and “our”) and operated 
              from Indonesia.
            </Longform>
            <Longform>
              This privacy policy has been compiled to better serve those 
              who are concerned with how their ‘Personally Identifiable Information’ 
              (PII) is being used online. PII, as described in US privacy law and 
              information security, is information that can be used on its own or 
              with other information to identify, contact, or locate a single person, 
              or to identify an individual in context. Please read our privacy policy 
              carefully to get a clear understanding of how we collect, use, protect, 
              or otherwise handle your PII.
            </Longform>
            <Longform>
              This Privacy Policy informs you of our policies regarding the collection, 
              use, and disclosure of personal data when you use our Service and the choices 
              you have associated with that data. We use your data to provide and improve 
              the Service. Service means the Sampld website and the Sampld App operated 
              by <strong>PT Chorus Digital Indonesia</strong>.
            </Longform>
            <Longform>
              We comply with Indonesian privacy laws and applicable international privacy laws.
            </Longform>
            <Longform>
              For US residents, we adopt this notice to comply with the California Consumer 
              Privacy Act and other US privacy laws.  Any terms defined in the CCPA have the 
              same meaning when used in this Policy. For users from Europe, see below 
              “General Data Protection Regulation (GDPR), (EU) 2016/679.
            </Longform>
            <Longform>
              By using the Service, you agree to the collection and use of information in 
              accordance with this policy. Unless otherwise defined in this Privacy Policy, 
              terms used in this Privacy Policy have the same meanings as in our Terms and 
              Conditions.
            </Longform>
            <br/>
            <strong>PERSONAL INFORMATION WE COLLECT</strong>
            <Longform>
              <strong>Personal information you provide to us (PII or Personal Data).</strong> 
              Personal information you may provide to us through the Service 
              or otherwise includes:
            </Longform>
            <ul>
                <li>
                  <Longform>
                    <strong>Personal data</strong>, personal information such as your name.
                  </Longform>
                </li>
                <li>
                  <Longform>
                    <strong>User Likes and Comments</strong>, we collect uploaded sounds (if any), 
                    user likes, and comments posted by the user.
                  </Longform>
                </li>
                <li>
                  <Longform>
                    <strong>Registration data</strong>, such as information that you provide to 
                    register for an account or sign up, including the day and month of your birth.
                    </Longform>
                  </li>
                <li>
                  <Longform>
                    <strong>Profile data</strong>, such as your username and password that you may 
                    set to establish an online account with us and your interests and preferences.
                  </Longform>
                </li>
                <li>
                  <Longform>
                    <strong>Marketing data</strong>, such as the email address or contact details 
                    that we use to send marketing communications and your preferences for receiving 
                    communications about our activities, events, sweepstakes and contests.
                  </Longform>
                </li>
                <li>
                  <Longform>
                    <strong>Other information</strong> that we may collect which is not specifically 
                    listed here, but which we will use in accordance with this Privacy Policy or as 
                    otherwise disclosed at the time of collection.
                  </Longform>
                </li>
            </ul>
            <Longform>
              <strong>Automatic collection.</strong> We and our service providers may automatically 
              log information about you, your computer or mobile device, and your activity occurring 
              on or through the Sites, such as:
            </Longform>
            <ul>
                <li>
                  <Longform>
                    <strong>Device data</strong>, such as your computer or mobile device operating 
                    system type and version number, manufacturer and model, browser type, screen resolution, 
                    IP address, the website you visited before browsing our site, and general location 
                    information such as city, state or geographic area.
                  </Longform>
                </li>
                <li>
                  <Longform>
                    <strong>Online activity data</strong>, such as pages or screens you viewed, 
                    how long you spent on a page or screen, navigation paths between pages or screens, 
                    information about your activity on a page or screen, access times, and duration of access.
                  </Longform>
                </li>
            </ul>
            <Longform>
              When you download the Sampld App, we collect your device ID. As you access your 
              account from the app we will collect your account activity
            </Longform>
            <Longform>
              <strong>Cookies and similar technologies.</strong> Some of our automatic 
              data collection is facilitated by cookies and similar technologies. 
              See our Cookie Policy for more information. 
            </Longform>
            <br/>
            <strong>HOW WE USE YOUR PERSONAL INFORMATION</strong>
            <Longform>
              We use your personal information for the following purposes and as 
              otherwise described in this Privacy Policy or at the time of collection:
            </Longform>
            <Longform>
              <strong>Service delivery.</strong> We may use your personal information to:
            </Longform>
            <ul>
                <li>
                  <Longform>
                    provide, operate and improve the Service, such as to enable you 
                    to make purchases of our pro version or any other in-app purchases;
                  </Longform>
                </li>
                <li>
                  <Longform>
                    establish and maintain your account on the Service;
                  </Longform>
                </li>
                <li>
                  <Longform>
                    communicate with you about the Service, including by 
                    sending you announcements, updates, security alerts, 
                    and support and administrative messages;
                  </Longform>
                </li>
                <li>
                  <Longform>
                    provide customer support and maintenance for the Service;
                  </Longform>
                </li>
            </ul>
            <Longform>
              <strong>Direct Marketing.</strong> We may use your personal information 
              to send you Sampld-related marketing communications as permitted by law. 
              You will have the ability to opt-out of our marketing and promotional 
              communications as described in the Your Choices section below.
            </Longform>
            <Longform>
              <strong>For research and development.</strong> We may use your personal 
              information for research and development purposes, including to analyze 
              and improve the Service and our business
            </Longform>
            <Longform>
              <strong>To create anonymous data.</strong> We may create aggregated, 
              de-identified or other anonymous data records from your personal 
              information and other individuals whose personal information we collect. 
              We make personal information into anonymous data by excluding information 
              (such as your name) that makes the data personally identifiable to you. 
              We may use this anonymous data and share it with third parties for our 
              lawful business purposes, including to analyze and improve the Service 
              and promote our business.
            </Longform>
            <Longform>
              <strong>To comply with laws and regulations.</strong> We use your 
              personal information as we believe necessary or appropriate to comply 
              with applicable laws, lawful requests, and legal process, such as to 
              respond to subpoenas or requests from government authorities.
            </Longform>
            <Longform>
              <strong>For compliance, fraud prevention and safety.</strong> We 
              may use your personal information and disclose it to law enforcement, 
              government authorities, and private parties as we believe necessary 
              or appropriate to: (a) protect our, your or others’ rights, privacy, 
              safety or property (including by making and defending legal claims); 
              (b) audit our internal processes for compliance with legal and 
              contractual requirements; (c) enforce the terms and conditions that 
              govern the Service; and (d) protect, investigate and deter against 
              fraudulent, harmful, unauthorized, unethical or illegal activity, 
              including cyber-attacks and identity theft.
            </Longform>
            <Longform>
              <strong>With your consent.</strong> In some cases we may specifically 
              ask for your consent to collect, use or share your personal information, 
              such as when required by law. 
            </Longform>
            <br/>
            <strong>HOW WE SHARE YOUR PERSONAL INFORMATION</strong>
            <Longform>
              We may share your personal information with the following third 
              parties and as otherwise described in this Privacy Policy or at 
              the time of collection:
            </Longform>
            <Longform>
              <strong>Service providers.</strong> Companies and individuals 
              that provide services on our behalf or help us operate the 
              Service or our business (such as order fulfillment, shipping, 
              payment processing, customer support, hosting, analytics, email 
              delivery, marketing, database management services, returns 
              processing and risk and fraud mitigation).
            </Longform>
            <Longform>
              <strong>Third party platforms.</strong> Social media and other 
              third party platforms that you connect to the Service, such as 
              when you use options to access the Service by logging into a 
              social media platform.  Please note, we do not control the third 
              party’s use of your personal information.
            </Longform>
            <Longform>
              <strong>The public.</strong> Other users of the Service and 
              the public, when you disclose personal information for public use. 
              We do not control how other users or third parties use any personal 
              information that you make available to them. Please be aware that any 
              information you post publicly can be cached, copied, screen captured or 
              stored elsewhere by others (e.g., search engines) before you have a 
              chance to edit or remove it.
            </Longform>
            <Longform>
              <strong>Professional advisors.</strong> Professional advisors, 
              such as lawyers, bankers, auditors and insurers, where necessary 
              in the course of the professional services that they render to us.
            </Longform>
            <Longform>
              <strong>Authorities and others.</strong> Law enforcement, 
              government authorities, and private parties, as we believe in 
              good faith to be necessary or appropriate to comply with law or 
              for the compliance, fraud prevention and safety purposes described above.
            </Longform>
            <br/>
            <strong>YOUR CHOICES</strong>
            <Longform>
              In this section, we describe the rights and choices available to all users.
            </Longform>
            <Longform>
              <strong>Access or update your account information.</strong> If you have 
              registered for an account with us, you may review and update certain 
              personal information in your account profile by logging into the account.
            </Longform>
            <Longform>
              <strong>Do Not Track.</strong> Some Internet browsers may be configured 
              to send “Do Not Track” signals to the online services that you visit. 
              We currently do not respond to “Do Not Track” or similar signals. 
              To find out more about “Do Not Track,” please 
              visit <a href="http://www.allaboutdnt.com" target="_blank">http://www.allaboutdnt.com</a>
            </Longform>
            <Longform>
              <strong>Privacy settings and location data.</strong> Users of our App 
              can disable our access to their device’s precise geo-location in 
              their mobile device settings.
            </Longform>
            <Longform>
              <strong>Choosing not to share your personal information.</strong> If 
              you do not provide information that we need to provide the Service, we 
              may not be able to provide you with the Service or certain features. 
              We will tell you what information you must provide to receive the 
              Service when we request it.
            </Longform>
            <Longform>
              <strong>Third-party platforms or social media networks.</strong> If 
              you choose to create an account through or connect the Service with 
              another third-party platform, you may have the ability to limit the 
              information that we may obtain from the third-party at the time you 
              log in to the Service using the third-party’s authentication service 
              or otherwise connect your account. You may also be able to control 
              your settings through the third-party’s platform or service after 
              you have connected your accounts.
            </Longform>
            <br/>
            <strong>DELETING DATA AND INFORMATION</strong>
            <Longform>
              Users on the App and Site can delete their Account. 
              Once an account is deleted, we will erase ALL data of the 
              User associated with their account including messages, 
              their connections, and their personal information. 
              The process can’t be undone once the user proceeds with 
              the deletion of data.
            </Longform>
            <br/>
            <strong>OTHER SITES AND SERVICES</strong>
            <Longform>
              The Sites may contain links to other websites and 
              online services operated by third parties. These 
              links are not an endorsement of, or representation 
              that we are affiliated with, any third party. In 
              addition, our content may be included on web pages 
              or online services that are not associated with us. 
              We do not control third party websites or online services, 
              and we are not responsible for their actions. Other 
              websites and services follow different rules regarding 
              the collection, use and sharing of your personal information. 
              We encourage you to read the privacy policies of the other 
              websites and online services you use.
            </Longform>
            <br/>
            <strong>SECURITY PRACTICES</strong>
            <Longform>
              The security of your personal information is important to us. 
              We employ a number of organizational, technical and physical 
              safeguards designed to protect the personal information we collect. 
              However, security risk is inherent in all internet and information 
              technologies and we cannot guarantee the security of your personal 
              information.
            </Longform>
            <br/>
            <strong>INTERNATIONAL DATA TRANSFERS</strong>
            <Longform>
              Sampld is headquartered in Indonesia and may have service providers 
              in other countries. Your personal information may be transferred to 
              Indonesia or other locations outside of your state, province, country, 
              or other governmental jurisdiction where privacy laws may not be as 
              protective as those in your jurisdiction. 
            </Longform>
            <br/>
            <strong>CCPA – For U.S.</strong>
            <Longform>
              Your California privacy rights. The CCPA grants California residents 
              the following rights. However, these rights are not absolute, and in 
              certain cases we may decline your request as permitted by law.
            </Longform>
            <ul>
                <li>
                  <Longform>
                    <strong>Information.</strong> You can request information about 
                    how we have collected, used and shared and used your Personal 
                    Information during the past 12 months.
                  </Longform>
                  <ul>
                    <li>
                      <Longform>The categories of Personal Information that we have collected.</Longform>
                    </li>
                    <li>
                      <Longform>The categories of sources from which we collected Personal Information.</Longform>
                    </li>
                    <li>
                      <Longform>The business or commercial purpose for collecting and/or selling Personal Information.</Longform>
                    </li>
                    <li>
                      <Longform>The categories of third parties with whom we share Personal Information.</Longform>
                    </li>
                    <li>
                      <Longform>Whether we have disclosed your Personal Information for a business purpose, 
                        and if so, the categories of Personal Information received by each category of third party recipient.</Longform>
                    </li>
                    <li>
                      <Longform>Whether we’ve sold your Personal Information, and if so, the categories of 
                        Personal Information received by each category of third party recipient.</Longform>
                    </li>
                  </ul>
                </li>
                <li>
                  <Longform>
                    <strong>Access.</strong> You can request a copy of the Personal Information that we 
                    have collected about you during the past 12 months.
                  </Longform>
                </li>
                <li>
                  <Longform>
                    <strong>Deletion.</strong> You can ask us to delete the Personal Information that 
                    we have collected from you.
                  </Longform>
                </li>
            </ul>
            <Longform>
              You are entitled to exercise the rights described above free 
              from discrimination in the form of legally prohibited increases 
              in the price or decreases in the quality of our Service.
            </Longform>
            <Longform>
              We have provided additional information about how you can 
              opt-out of the use of your information for interest-based 
              advertising in the “Your Choices” section of our Cookie Policy.
            </Longform>
            <Longform>
              How to exercise your California rights. You may exercise your 
              California privacy rights described above as follows:
            </Longform>
            <ul>
              <li>
                <Longform>
                  <strong>Right to information</strong>, access and deletion. 
                  You can request to exercise your information, access and 
                  deletion rights by emailing <a href="mailto://legal@sampld.app" target="_blank">legal@sampld.app</a>.  We reserve the right 
                  to confirm your California residence to process your requests and 
                  will need to confirm your identity to process your requests to 
                  exercise your information, access or deletion rights. 
                  As part of this process, government identification may be required. 
                  Consistent with California law, you may designate an authorized agent 
                  to make a request on your behalf. In order to designate an authorized 
                  agent to make a request on your behalf, you must provide a valid power 
                  of attorney, the requester’s valid government-issued identification, 
                  and the authorized agent’s valid government-issued identification. 
                  We cannot process your request if you do not provide us with sufficient 
                  detail to allow us to understand and respond to it.
                </Longform>
              </li>
              <li>
                <Longform>
                  <strong>Request a list of third party marketers.</strong> California’s 
                  “Shine the Light” law (California Civil Code § 1798.83) allows California 
                  residents to ask companies with whom they have formed a business relationship 
                  primarily for personal, family or household purposes to provide certain information 
                  about the companies’ sharing of certain personal information with third parties 
                  for their direct marketing purposes during the preceding year (if any). 
                  You can submit such a request by sending an email to cakeartbyabby@gmail.com with 
                  “Shine the Light” in the subject line. The request must include your current name, 
                  street address, city, state, and zip code and attest to the fact that you are a 
                  California resident.
                </Longform>
              </li>
            </ul>
            <Longform>
              We cannot process your request if you do not provide us with 
              sufficient detail to allow us to understand and respond to it.
            </Longform>
            <br/>
            <strong>GENERAL DATA PROTECTION REGULATION (GDPR)</strong>
            <Longform>
              If you are from the European Economic Area (EEA), Sampld 
              legal basis for collecting and using the personal information 
              described in this Privacy Policy depends on the Personal Data 
              we collect and the specific context in which we collect it.
            </Longform>
            <Longform>
              Sampld may process your Personal Data because:
            </Longform>
            <ul>
              <li>
                <Longform>We need to perform a contract with you</Longform>
              </li>
              <li>
                <Longform>You have given us permission to do so</Longform>
              </li>
              <li>
                <Longform>The processing is in our legitimate interests and it’s not overridden by your rights</Longform>
              </li>
              <li>
                <Longform>For payment processing purposes</Longform>
              </li>
              <li>
                <Longform>To comply with the law</Longform>
              </li>
            </ul>
            <br/>
            <strong>Retention of Data</strong>
            <Longform>
              Sampld will retain your Personal Data only for as long as 
              is necessary for the purposes set out in this Privacy Policy. 
              We will retain and use your Personal Data to the extent necessary 
              to comply with our legal obligations (for example, if we are required 
              to retain your data to comply with applicable laws), resolve disputes, 
              and enforce our legal agreements and policies.
            </Longform>
            <Longform>
              Sampld will also retain Usage Data for internal analysis purposes. 
              Usage Data is generally retained for a shorter period of time, except 
              when this data is used to strengthen the security or to improve the 
              functionality of our Service, or we are legally obligated to retain 
              this data for longer time periods.
            </Longform>
            <br/>
            <strong>Transfer of Data</strong>
            <Longform>
              Your information, including Personal Data, may be transferred to — 
              and maintained on — computers located outside of your state, province, 
              country or other governmental jurisdiction where the data protection 
              laws may differ from those from your jurisdiction.
            </Longform>
            <Longform>
              Your consent to this Privacy Policy followed by your submission of 
              such information represents your agreement to that transfer.
            </Longform>
            <Longform>
              Sampld will take all steps reasonably necessary to ensure that your 
              data is treated securely and in accordance with this Privacy Policy 
              and no transfer of your Personal Data will take place to an organization 
              or a country unless there are adequate controls in place including the 
              security of your data and other personal information.
            </Longform>
            <br/>
            <strong>Disclosure of Data</strong>
            <Longform>
              Legal Requirements
            </Longform>
            <Longform>
              Sampld may disclose your Personal Data in the good faith 
              belief that such action is necessary to:
            </Longform>
            <ul>
              <li><Longform>To comply with a legal obligation</Longform></li>
              <li><Longform>To protect and defend the rights or property of Sampld</Longform></li>
              <li><Longform>To prevent or investigate possible wrongdoing in connection with the Service</Longform></li>
              <li><Longform>To protect the personal safety of users of the Service or the public</Longform></li>
              <li><Longform>To protect against legal liability</Longform></li>
            </ul>
            <br/>
            <strong>Security of Data</strong>
            <Longform>
              Security of your data is important to us but remember that no 
              method of transmission over the Internet, or method of electronic 
              storage is 100% secure. While we strive to use commercially acceptable 
              means to protect your Personal Data, we cannot guarantee its 
              absolute security.
            </Longform>
            <br/>
            <strong>Your rights under the GDPR</strong>
            <Longform>
              If you are a resident of the European Economic Area (EEA), 
              you have certain data protection rights. Sampld aims to take reasonable 
              steps to allow you to correct, amend, delete, or limit the use of your 
              Personal Data.
            </Longform>
            <Longform>
              If you wish to be informed what Personal Data we hold about you and if 
              you want it to be removed from our systems, please contact us.
            </Longform>
            <Longform>
              In certain circumstances, you have the following data protection rights:
            </Longform>
            <ul>
              <li>
                <Longform>
                  <strong>The right to access, update or to delete the information we have on you.</strong> Whenever 
                  made possible, you can access, update or request deletion of your 
                  Personal Data directly within your account settings section. If 
                  you are unable to perform these actions yourself, please contact 
                  us to assist you.
                </Longform>
              </li>
              <li>
                <Longform>
                  <strong>The right of rectification.</strong> You have the right 
                  to have your information rectified if that information is 
                  inaccurate or incomplete.
                </Longform>
              </li>
              <li>
                <Longform>
                  <strong>The right to object.</strong> You have the right to 
                  object to our processing of your Personal Data.
                </Longform>
              </li>
              <li>
                <Longform>
                  <strong>The right to restriction.</strong> You have the 
                  right to request that we restrict the processing of your 
                  personal information.
                </Longform>
              </li>
              <li>
                <Longform>
                  <strong>The right to data portability.</strong> You have 
                  the right to be provided with a copy of the information we 
                  have on you in a structured, machine-readable and 
                  commonly used format.
                </Longform>
              </li>
              <li>
                <Longform>
                  <strong>The right to withdraw consent.</strong> You also 
                  have the right to withdraw your consent at any time when 
                  Sampld relied on your consent to process your personal 
                  information.
                </Longform>
              </li>
            </ul>
            <Longform>
              Please note that we may ask you to verify your identity before responding to such requests.
            </Longform>
            <Longform>
              You have the right to complain to a Data Protection 
              Authority about our collection and use of your Personal 
              Data. For more information, please contact your local data 
              protection authority in the European Economic Area (EEA).
            </Longform>
            <br/>
            <strong>Service Providers</strong>
            <Longform>
              We may employ third party companies and individuals to 
              facilitate our Service (“Service Providers”), to provide the 
              Service on our behalf, to perform Service-related services or 
              to assist us in analyzing how our Service is used.
            </Longform>
            <Longform>
              These third parties have access to your Personal Data only 
              to perform these tasks on our behalf and are obligated not 
              to disclose or use it for any other purpose.
            </Longform>
            <br/>
            <strong>ANALYTICS</strong>
            <Longform>
              We may use third-party Service Providers to monitor and analyze the use of our Service.
            </Longform>
            <ul><li><strong>Google Analytics</strong></li></ul>
            <Longform>
              Google Analytics is a web analytics service offered by 
              Google that tracks and reports website traffic. Google uses 
              the data collected to track and monitor the use of our Service. 
              This data is shared with other Google services. Google may use 
              the collected data to contextualize and personalize the ads of 
              its own advertising network.
            </Longform>
            <Longform>
              For more information on the privacy practices of Google, 
              please visit the Google Privacy & Terms web 
              page: <a href="http://www.google.com/intl/en/policies/privacy/" target="_blank">http://www.google.com/intl/en/policies/privacy/</a>
            </Longform>
            <br/>
            <strong>Advertising</strong>
            <Longform>
              We may use third-party Service Providers 
              to show advertisements to you to help support 
              and maintain our Service.
            </Longform>
            <br/>
            <strong>PAYMENTS</strong>
            <Longform>
              We may provide in-app purchase and/or paid services 
              within the Service. In that case, we use third-party 
              services for payment processing (e.g. payment processors).
            </Longform>
            <Longform>
              We will not store or collect your payment card details. 
              That information is provided directly to our third-party 
              payment processors whose use of your personal information is 
              governed by their Privacy Policy. These payment processors adhere 
              to the standards set by PCI-DSS as managed by the PCI Security 
              Standards Council, which is a joint effort of brands like Visa, 
              Mastercard, American Express and Discover. PCI-DSS requirements 
              help ensure the secure handling of payment information.
            </Longform>
            <Longform>The payment processors we work with are:</Longform>
            <ul><li><strong>Apple Store In-App Payments</strong></li></ul>
            <Longform>
              Their Privacy Policy can be viewed 
              at <a href="https://www.apple.com/legal/privacy/en-ww/" target="_blank">https://www.apple.com/legal/privacy/en-ww/</a>
            </Longform>
            <ul><li><strong>Google Play In-App Payments</strong></li></ul>
            <Longform>
              Their Privacy Policy can be viewed 
              at <a href="https://www.google.com/policies/privacy/" target="_blank">https://www.google.com/policies/privacy/</a>
            </Longform>
            <br/>
            <strong>CHILDREN’S PRIVACY</strong>
            <Longform>Our Service does not address anyone under the age of 18 (“Children”).</Longform>
            <Longform>
              We do not knowingly collect personally identifiable information 
              from anyone under the age of 18. If you are a parent or guardian 
              and you are aware that your Child has provided us with Personal 
              Data, please contact us. If we become aware that we have collected 
              Personal Data from children without verification of parental consent, 
              we take steps to remove that information from our servers.
            </Longform>
            <br/>
            <strong>CHANGES TO THIS PRIVACY POLICY</strong>
            <Longform>
              We may update our Privacy Policy from time to time. We will 
              notify you of any changes by posting the new Privacy Policy on 
              this page.
            </Longform>
            <Longform>
              We will let you know via email and/or a prominent notice on 
              our Service, prior to the change becoming effective and update 
              the “effective date” at the top of this Privacy Policy.
            </Longform>
            <Longform>
              You are advised to review this Privacy Policy periodically 
              for any changes. Changes to this Privacy Policy are effective 
              when they are posted on this page.
            </Longform>
            <br/>
            <strong>CONTACT US</strong>
            <Longform>If you have any questions about this Privacy Policy, please contact us:</Longform>
            <Longform><a href="mailto://legal@sampld.app" target="_blank">legal@sampld.app</a></Longform>
          </Content>
        );
      case "terms":
        return (
          <Content>
            <ContentTitle>Terms & Conditions</ContentTitle>
            <Longform>Effective date: 1st August 2021</Longform>
            <strong>Introduction</strong>
            <Longform>
              The Sampld website located on <a href="https://sampld.app" target="_blank">sampld.app</a>, 
              the web app <a href="https://open.sampld.app" target="_blank">open.sampld.app</a> (“Site”) 
              and the Sampld Mobile Applications (“App”) available on Apple App Store (IOS) 
              and Google Play Store (Android), are owned and operated 
              by <strong>PT Chorus Digital Indonesia</strong> from Indonesia. 
            </Longform>
            <Longform>
              Throughout the Site, the terms “we”, “us”, “platform”, “Sampld”, and 
              “our” refer to <strong>PT Chorus Digital Indonesia</strong>. We offer 
              the Sampld Site and App, including all information, tools, and services 
              available from this site to you, the user, conditioned upon your acceptance 
              of all terms, conditions, policies, and notices stated here.
            </Longform>
            <Longform>
              By visiting our site and/or purchasing any of our service, you (“User”) 
              engage in our “Services” and agree to be bound by the following terms and 
              conditions (“Terms”), including those additional terms and conditions and 
              policies referenced herein and/or available by hyperlink. These Terms and 
              Conditions apply to all users of the Site and App including without 
              limitation users who are browsers, customers, users, and/ or contributors 
              of content.
            </Longform>
            <Longform>
              <strong>
                Please read these Terms carefully before downloading, installing or using 
                the App and when using our Site. By downloading, installing, accessing, 
                the App or by browsing the Site, you accept and agree to these Terms which 
                bind you legally. If you do not agree with these Terms, our Privacy Policy, 
                or any other of our policy, you should not use the Services.
              </strong>
            </Longform>
            <br/>
            <strong>General Information</strong>
            <ul>
              <li>
                <Longform>
                  <strong>About.</strong> Sampld is a sound sharing platform made for 
                  musicians and content creators to share and download quality sounds 
                  for free, which are royalty-free as well. Users can share sounds that 
                  they own the rights to, and other users can leave comments on it as well. 
                  For more information on the products/services we offer, please find more 
                  details on <a href="https://sampld.app" target="_blank">sampld.app</a> or on our App.
                </Longform>
              </li>
              <li>
                <Longform>
                  <strong>Sole discretion.</strong> We reserve the right to add/discontinue 
                  any product or service anytime at the sole discretion and without notice. 
                  We also reserve the right to take appropriate steps to protect Sampld 
                  and/or its Members/Users from any abuse/misuse as it deems appropriate 
                  in its sole discretion.
                </Longform>
              </li>
            </ul>
            <br/>
            <strong>App Updates and Functionality</strong>
            <Longform>
              From time to time, updates to the App may be made available through 
              the App Store and Play Store. Depending on the update, and whether 
              you utilized the App Store or Play Store to download and access the 
              App, you may not be able to use the App until you have installed the 
              latest version. In addition, you acknowledge that the App is provided 
              over the Internet and mobile networks and so the quality and availability 
              of the App may be affected by factors outside our reasonable control. 
              Accordingly, we do not accept any responsibility for any connectivity 
              issues that you may experience when using the App. In addition, you 
              acknowledge and agree that you (and not us) are responsible for obtaining 
              and maintaining all telecommunications, broadband and computer hardware, 
              equipment and services needed to access and use the App, and for paying 
              any data charges that may be charged to you by your network provider in 
              connection with your use of the App. 
            </Longform>
            <br/>
            <strong>Store Rules</strong>
            <Longform>
              With respect to downloading the App, you agree to comply with the App 
              Store Rules and Play Store Rules, as applicable. You acknowledge that 
              the availability of the App may be dependent on the App Store or Play 
              Store from which you receive the App. You acknowledge these Terms are 
              between you and Sampld and not with the App Store or Play Store. The App 
              Store and Play Store are not responsible for the App, the content thereof, 
              maintenance, support services, and warranty therefore, and addressing any 
              claims relating thereto (e.g., product liability, legal compliance, or 
              intellectual property infringement). You acknowledge that the App Store 
              and Play Store (and their respective subsidiaries) are third party 
              beneficiaries to these Terms and will have the right to enforce these 
              Terms against you. 
            </Longform>
            <br/>
            <strong>Permitted Use</strong>
            <Longform>
              You agree to use the Site, App and the Services only for purposes 
              that are permitted by these Terms and Conditions and in compliance 
              with all applicable laws, regulations, and generally accepted practices 
              or guidelines in the relevant jurisdictions. You may only use the 
              Services for your non-commercial, non-exclusive, non-assignable, 
              non-transferable, and limited personal use, and no other purposes.
            </Longform>
            <Longform>You will not (and will not attempt to):</Longform>
            <ol type="a">
              <li>
                <Longform>Use the Services for any illegal or unlawful purpose;</Longform>
              </li>
              <li>
                <Longform>Access any of the Services by any means other than through the interface that is provided by Sampld;</Longform>
              </li>
              <li>
                <Longform>
                  Gain unauthorised access to Sampld’s computer system or engage in 
                  any activity that interferes with the performance of, or impairs 
                  the functionality or security of the Services, Sampld’s networks, 
                  and computer systems;
                </Longform>
              </li>
              <li>
                <Longform>
                  Access any of the the Services through any automated means or 
                  with any automated features or devices (including use of scripts 
                  or web crawlers);
                </Longform>
              </li>
              <li>
                <Longform>
                  Access or collect any personally identifiable information, 
                  including any names, email addresses or other such information 
                  for any purpose, including, without limitation, commercial 
                  purposes;
                </Longform>
              </li>
              <li>
                <Longform>Reproduce, duplicate, copy, sell, trade, or resell any aspect of the Services for any purpose; and</Longform>
              </li>
              <li>
                <Longform>
                  Reproduce, duplicate, copy, sell, trade or resell any products 
                  or services bearing any trademark, service mark, trade name, 
                  logo or service mark owned by Sampld in a way that is likely 
                  or intended to confuse the owner or authorised user of such 
                  marks, names or logos.
                </Longform>
              </li>
            </ol>
            <br/>
            <strong>Interaction with other Members.</strong>
            <Longform>
              You are solely responsible for your communications with other 
              Sampld Members. Sampld reserves the right, but has no obligation, 
              to monitor disputes between you and other Members. Sampld also 
              reserves the right to take appropriate action against errant members. 
              However, Sampld is not obliged to share such action with other members, 
              including complainants. Sampld expressly disclaims any responsibility or 
              liability for any transactions or interactions between the members 
              inter-se.
            </Longform>
            <br/>
            <strong>General Terms</strong>
            <Longform>In the following terms; “music”, “sound” or “content” may refer to content/sound/music collectively.</Longform>
            <strong>Free and Paid Version</strong>
            <ul>
              <li>
                <Longform>
                  Free tier users will have a sound in the resulting end video 
                  overlaid with a voice: "sound from Sampld". Paid tier users/subscription 
                  plan users will be exempted from the “sound from Sampld” voice in the 
                  resulting end video.
                </Longform>
              </li>
              <li>
                <Longform>There is a 'Sync with video' feature that allows users to sync sounds with any videos on the device.</Longform>
              </li>
            </ul>
            <strong>Content Copyrights</strong>
            <ul>
              <li>
                <Longform>
                  The content you submit must be royalty-free, non-assignable, 
                  nonexclusive, revocable, and non-sub licensable license.
                </Longform>
              </li>
              <li>
                <Longform>
                  Each uploaded music/content files are required to pass our 
                  quality check and criteria and can be rejected at our sole 
                  discretion. The Content you submit must not include third-party 
                  intellectual property (such as copyrighted material) unless you 
                  have permission from that party or are otherwise legally entitled 
                  to do so. You are legally responsible for the Content you submit 
                  to our platform.
                </Longform>
              </li>
              <li>
                <Longform>Users must only upload music/content which they own or have the rights or permission to do so. </Longform>
              </li>
            </ul>
            <strong>Licesing rights -  Download</strong>
            <ul>
              <li>
                <Longform>
                  Users can download sounds from our platform for free for both 
                  commercial, non-commercial and non-royalty use. We appreciate 
                  any attribution of our platform when you use or re-publish the 
                  music.
                </Longform>
              </li>
              <li>
                <Longform>
                  Users are prohibited from selling the downloaded sounds or 
                  generating any revenue unless an exceptional significant 
                  modification is made to the downloaded sound.
                </Longform>
              </li>
              <li>
                <Longform>
                  Users are also strictly prohibited from compiling sounds 
                  (by itself or with modification) from Sampld to create packs, 
                  or to replicate a similar or competing service.
                </Longform>
              </li>
              <li>
                <Longform>
                  Music/Content placed in a complete or any archived downloadable 
                  format in any (but not limited to) website, streaming/download 
                  platform, mobile app, or in any way that enables it to be 
                  redistributed.
                </Longform>
              </li>
            </ul>
            <br/>
            <strong>Limited License</strong>
            <Longform>
              You may not: (a) resell or make any commercial use of this Site and 
              the App or any of the contents of our Services; (b) modify, adapt, 
              translate, reverse engineer, decompile, disassemble or convert any 
              of the contents of the Services not intended to be so read; (c) copy, 
              imitate, mirror, reproduce, distribute, publish, download, display, 
              perform, post or transmit any of the contents of the Services in any 
              form or by any means; or (d) use any data mining, bots, spiders, 
              automated tools or similar data gathering and extraction methods on 
              the contents of the Services or to collect any information from the 
              Site, App or any other user.
            </Longform>
            <Longform>
              Your use of this Site and the App is at your own risk. You agree 
              that you will be personally responsible for your use of this Site 
              and the App. If we determine, in our sole discretion, that you 
              engaged in prohibited activities, were not respectful of other 
              users, or otherwise violated the Terms and Conditions, we may deny 
              you access to our Services on a temporary or permanent basis and 
              any decision to do so is final.
            </Longform>
            <br/>
            <strong>Accounts, Registrations, and Passwords</strong>
            <Longform>
              You are solely responsible for maintaining the confidentiality 
              of your account and password(s) and for restricting access to 
              your computer and mobile/tablet.  If you open an account, 
              register, or otherwise provide us with any information, you 
              agree to provide us with current, complete, and accurate 
              information as requested by any forms. Sampld is not responsible 
              for any errors or delays in responding to any inquiry or request 
              caused by any incorrect, outdated, or incorrect information 
              provided by you or any technical problems beyond the control of 
              Sampld. You may not disclose the Password to another person or 
              entity or permit another entity to access the Site and Apps using 
              such a Password. You must notify Sampld immediately of any breach 
              of security or unauthorised use of your account. Sampld cannot be 
              responsible and disclaims all liability in connection with, the use 
              of any information that you post or display on the Site and App. 
              Multiple profiles of the same person are not allowed on the Site 
              and App.
            </Longform>
            <br/>
            <strong>Refund Policy</strong>
            <Longform>For more information on this section, please refer to our Refund policy.</Longform>
            <br/>
            <strong>Intellectual Property Rights</strong>
            <Longform>
              Your use of the Site, App and its contents grants no rights to you 
              concerning any copyright, designs, and trademarks and all other 
              intellectual property and material rights mentioned, displayed, 
              or relating to the Content (defined below) on the Site and App. 
              All Content, including third party trademarks, designs, and related 
              intellectual property rights mentioned or displayed on this Site and 
              the App, are protected by national intellectual property and other 
              laws. Any unauthorised reproduction, redistribution or other use of 
              the Content is prohibited and may result in civil and criminal 
              penalties. You may use the Content only with our prior written and 
              express authorisation. To inquire about obtaining authorisation to 
              use the Content, please contact us at <a href="mailto://legal@sampld.app" target="_blank">legal@sampld.app</a>. 
              In addition to the intellectual property rights mentioned above, 
              "Content" is defined as any graphics, photographs, including all 
              image rights, sounds, music, video, audio, or text on the Site and App.
            </Longform>
            <br/>
            <strong>Monitoring Activity</strong>
            <Longform>
              Sampld has no obligation to monitor this Site and the App or any 
              portion thereof. However, we reserve the right to review any posted 
              content and remove, delete, redact or otherwise modify such content, 
              in our sole discretion, at any time and from time to time, without 
              notice or further obligation to you. Sampld has no obligation to 
              display or post any content. Sampld, subject to the Privacy Policy 
              reserves the right to disclose, at any time and from time to time, 
              any information or posted the content that it deems necessary or 
              appropriate, including without limitation to satisfy any applicable 
              law, regulation, contractual obligation, legal, dispute process, or 
              governmental request.
            </Longform>
            <br/>
            <strong>Disclaimer</strong>
            <Longform>
              Sampld has no obligation to monitor this Site and the App or any 
              portion thereof. However, we reserve the right to review any posted 
              content and remove, delete, redact or otherwise modify such content, 
              in our sole discretion, at any time and from time to time, without 
              notice or further obligation to you. Sampld has no obligation to 
              display or post any content. Sampld, subject to the Privacy Policy 
              reserves the right to disclose, at any time and from time to time, 
              any information or posted the content that it deems necessary or 
              appropriate, including without limitation to satisfy any applicable 
              law, regulation, contractual obligation, legal, dispute process, or 
              governmental request.  
            </Longform>
            <br/>
            <strong>Limitation of Liability</strong>
            <Longform>
              You agree that in no event shall Sampld be liable to you, or any 
              third party, for any lost profits, incidental, consequential, 
              punitive, special, or indirect damages arising out of or in 
              connection with the Site and App or the Terms and Conditions, 
              even if advised as to the possibility of such damages, regardless 
              of whether the claim for such damages is based in contract, tort, 
              strict liability or otherwise. This limitation on liability includes, 
              but is not limited to, any (i) errors, mistakes, or inaccuracies in 
              any Content or for any loss or damage of any kind incurred by you as 
              a result of your use of or reliance on the Content; (ii) the 
              transmission of any bugs, viruses, Trojan horses or the like which 
              may infect your equipment, failure of mechanical or electronic equipment; 
              (iii) unauthorised access to or use of the Site or Apps secure servers 
              and/or any personal information and/or financial information stored therein; 
              or (iv) theft, operator errors, strikes or other labor problems or any force 
              majeure. You agree that you use the App and/or any Third Party Sites at your 
              own risk. You further understand and agree that we are not responsible or 
              liable for your illegal, unauthorized, or improper use of information transmitted, 
              monitored, stored or received using the App. 
            </Longform>
            <br/>
            <strong>Indemnification</strong>
            <Longform>
              You agree to indemnify and hold Sampld and its subsidiaries, affiliates, 
              officers, directors, agents, and employees, harmless from and against 
              any suit, action, claim, demand, penalty or loss, including reasonable 
              attorneys’ fees, made by or resulting from any third party due to or 
              arising out of your use of our Services, breach of the Terms and Conditions 
              or the materials it incorporates by reference, or your violation of any 
              law, regulation, order or other legal mandates, or the rights of a third 
              party.
            </Longform>
            <br/>
            <strong>Governing Laws</strong>
            <Longform>
              These Terms and Conditions shall be governed by and construed in 
              accordance with the law of Indonesia and you hereby submit to the 
              exclusive jurisdiction of the Indonesian courts.
            </Longform>
            <br/>
            <strong>Privacy & Cookies</strong>
            <Longform>
              For more information on how we collect your information and cookies, 
              please refer to our Privacy Policy and Cookie Policy.
            </Longform>
            <br/>
            <strong>Changes</strong>
            <Longform>
              We reserve the right to update and revise these Terms and Conditions 
              at any time. You will know if these Terms and Conditions have been 
              revised since your last visit to the website or the App by referring 
              to the "Effective Date of Current Policy" date at the top of this page. 
              Your use of our Site and App constitutes your acceptance of these Terms 
              and Conditions as amended or revised by us from time to time, and you 
              should, therefore, review these Terms and Conditions regularly.
            </Longform>
            <br/>
            <strong>Severability</strong>
            <Longform>
              We shall be permitted to assign, transfer, or subcontract our rights 
              and obligations under these terms without your consent or any notice 
              to you. You shall not be permitted to assign, transfer, or subcontract 
              any of your rights and obligations under this agreement.
            </Longform>
            <br/>
            <strong>Force Majeure</strong>
            <Longform>
              Sampld is not liable for any delays caused by circumstances beyond 
              Sampld’s control, e.g. general labour dispute, extreme weather, acts 
              of war, fire, lightning, terrorist attacks, changed governmental orders, 
              technical problems, defects in power- /tele-/computer communications or 
              other communication and defects or delays in the service by sub-suppliers 
              due to circumstances set forth above. Such circumstances shall result in 
              relief from damages and other measures. If any such situation should arise, 
              Sampld shall inform the Customer accordingly both at the beginning and the 
              end of the period for the current situation. If the situation has lasted 
              for more than two months, both the Customer and Sampld are entitled to 
              terminate the purchase with immediate effect.
            </Longform>
            <br/>
            <strong>Entire Agreement</strong>
            <Longform>
              These Terms and Conditions set forth the entire understanding and 
              agreement between you and Sampld concerning the subject matter 
              herein and supers all prior or contemporaneous communications and 
              proposals, whether electronic, oral or written concerning the Site 
              and App. A printed version of these Terms and Conditions and any 
              notice given in electronic form shall be admissible in judicial or 
              administrative proceedings based upon or relating to these Terms 
              and Conditions to the same extent and subject to the same conditions 
              as other business documents and records originally generated and 
              maintained in printed form. Any rights not expressly granted herein 
              are reserved. You may not assign the Terms and Conditions, or assign, 
              transfer or sublicense your rights therein. A failure to act 
              concerning a breach by you or others does not waive Sampld's right 
              to act concerning subsequent or similar breaches.
            </Longform>
            <br/>
            <strong>Violation and Termination</strong>
            <Longform>
              Any conduct that in any way violates these Terms or any posted 
              restrictions or guidelines may result, in our sole discretion, 
              in the termination of your license and right to utilize the 
              Services to access Content or for any other purpose, and/or our 
              pursuit of any legal damages or remedies. If necessary, or as 
              authorized under applicable law, we may cooperate with local, 
              state and/or federal authorities to protect the Sites, the App, 
              the Services, the Content, Sampld, its Affiliates, Licensors, 
              members, employees, agents and/or visitors; to comply with 
              applicable laws; or to prevent unauthorized access or use of 
              the Services or the Content. We retain the right to deny access 
              to the Services, in our sole discretion, to any visitor/user for 
              any reason, including for any violation of these Terms.
            </Longform>
            <br/>
            <strong>Contact Us</strong>
            <Longform>For any questions, complaints, and queries or to report any violations, kindly get in touch:</Longform>
            <Longform><strong>PT Chorus Digital Indonesia</strong></Longform>
            <Longform>Email: <a href="mailto://legal@sampld.app" target="_blank">legal@sampld.app</a></Longform>
          </Content>
        );
      case "cookie":
        return (
          <Content>
            <ContentTitle>Cookie Policy</ContentTitle>
            <Longform>Effective date: 1st August 2021</Longform>
            <Longform>
              This Cookie Policy explains how <a href="https://sampld.app" target="_blank">sampld.app</a>, 
              the web app <a href="https://open.sampld.app" target="_blank">open.sampld.app</a> (“Sampld”, “we”, 
              “us” or “our”) uses cookies and similar technologies in connection with 
              the <a href="https://sampld.app" target="_blank">sampld.app</a> website.
            </Longform>
            <br/>
            <strong>What are cookies?</strong>
            <Longform>
              Cookies are small text files placed on your computer by 
              websites and sometimes by emails. They provide useful information 
              to organizations, which helps to make your visits to their websites 
              more effective and efficient. We use cookies to ensure that we are 
              able to understand how you use our websites and to ensure that we 
              can make improvements to the websites.
            </Longform>
            <Longform>
              Cookies do not contain any personal or confidential information about you.
            </Longform>
            <br/>
            <strong>How we use cookies</strong>
            <Longform>
              We use cookies to ensure that you get the best from our website. 
              The first time that you visit our website you will be asked to 
              consent to our use of cookies and we suggest that you agree to 
              allow the cookies to be active on your device whilst you visit 
              and browse our website to ensure that you experience our website 
              fully.
            </Longform>
            <Longform>
              The types of cookies that we may use include:
            </Longform>
            <ul>
              <li>
                <strong>Session cookies</strong>
                <Longform>
                  Session cookies last only for the duration of your visit and 
                  are deleted when you close your browser. These facilitate 
                  various tasks such as allowing a website to identify that a 
                  user of a particular device is navigating from page to page, 
                  supporting website security or basic functionality.
                </Longform>
              </li>
              <li>
                <strong>Persistent cookies</strong>
                <Longform>
                  Persistent cookies last after you have closed your browser, 
                  and allow a website to remember your actions and preferences. 
                  Sometimes persistent cookies are used by websites to provide 
                  targeted advertising based upon the browsing history of the device. 
                  We use persistent cookies to allow us to analyze users' visits to 
                  our site. These cookies help us to understand how customers arrive 
                  at and use our site so we can improve the overall service.
                </Longform>
              </li>
              <li>
                <strong>Strictly necessary cookies</strong>
                <Longform>
                  These cookies are essential in order to enable you to move 
                  around the website and use its features, and ensure the security 
                  of your experience. Without these cookies services you have asked 
                  for, such as applying for products and managing your accounts, 
                  cannot be provided. These cookies don’t gather information about 
                  you for the purposes of marketing.
                </Longform>
              </li>
              <li>
                <strong>Performance cookies</strong>
                <Longform>
                  These cookies collect information about how visitors use a web site, 
                  for instance which pages visitors go to most often, and if they get 
                  error messages from web pages. All information these cookies collect 
                  is only used to improve how a website works, the user experience and 
                  to optimize our advertising. By using our websites you agree that we 
                  can place these types of cookies on your device, however you can block 
                  these cookies using your browser settings.
                </Longform>
              </li>
              <li>
                <strong>Functionality cookies</strong>
                <Longform>
                  These cookies allow the website to remember choices you make (such as 
                  your username). The information these cookies collect is anonymized 
                  (i.e. it does not contain your name, address etc.) and they do not 
                  track your browsing activity across other websites. By using our 
                  websites you agree that we can place these types of cookies on your 
                  device, however you can block these cookies using your browser 
                  settings. 
                </Longform>
              </li>
              <li>
                <strong>Targeting cookies</strong>
                <Longform>
                  These cookies collect several pieces of information about your 
                  browsing habits. [They are usually placed by third party advertising 
                  networks]. They remember that you have visited a website and this 
                  information is shared with other organizations such as media publishers. 
                  These organizations do this in order to provide you with targeted adverts 
                  more relevant to you and your interests. 
                </Longform>
              </li>
              <li>
                <strong>Third party cookies</strong>
                <Longform>
                  Please note that third parties (including, for example, advertising 
                  networks and providers of external services like web traffic analysis 
                  services) may also use cookies, over which we have no control. These 
                  cookies are likely to be analytical/performance cookies or targeting 
                  cookies.
                </Longform>
              </li>
            </ul>
            <br/>
            <strong>Managing Cookies</strong>
            <Longform>
              You can control and/or delete cookies as you wish – for details, 
              see <a href="https://www.aboutcookies.org" target="_blank">aboutcookies.org</a>. 
              You can delete all cookies that are already on your computer and you can 
              set most browsers to prevent them from being placed. If you do this, 
              however, you may have to manually adjust some preferences every time 
              you visit our website or use our Platform and some services and 
              functionalities we offer may not work.
            </Longform>
            <Longform>To restrict or handle cookies, please see the ‘Help’ section of your internet browser.</Longform>
            <Longform>
              For any further information, please contact 
              us <a href="mailto://legal@sampld.app" target="_blank">legal@sampld.app</a>
            </Longform>
          </Content>
        );
      case "refund":
        return(
          <Content>
            <ContentTitle>Refund Policy</ContentTitle>
            <Longform>Effective date: 1st August 2021</Longform>
            <Longform>
              Welcome to the Refund Policy of <a href="https://sampld.app" target="_blank">sampld.app</a> (“Sampld”, 
              “we”, “us” and “our”) which is owned and operated by <strong>PT Chorus Digital Indonesia</strong>.
            </Longform>
            <br/>
            <strong>Subscriptions</strong>
            <ul>
              <li>
                <Longform>
                  Refunds can be requested through the respective stores, 
                  but the purchase may not be refunded. Cancellations should 
                  be made before the end of the billing period (e.g. a month).
                </Longform>
              </li>
              <li>
                <Longform>
                  Once you purchase a subscription plan, you hereby grant Sampld 
                  permission to automatically charge the subscription fee to your 
                  chosen payment method at the beginning of each applicable payment 
                  period unless you cancel before the billing period. 
                </Longform>
              </li>
              <li>
                <Longform>
                  You further agree that Sampld may charge any other applicable 
                  fees for the Service, including any early termination fee, to 
                  your payment method.
                </Longform>
              </li>
            </ul>
            <br/>
            <strong>For Apple Users</strong>
            <Longform>Unfortunately, Apple manages all its subscriptions and purchases directly.</Longform>
            <Longform>This means that we are not able to refund, cancel, or manage accounts created through Apple.</Longform>
            <Longform>
              To request a refund please contact Apple directly using the information 
              found on your emailed receipt. You can <a href="https://reportaproblem.apple.com/" target="_blank">click here</a> to 
              start your refund request through Apple.
            </Longform>
            <br/>
            <strong>For Google PlayStore</strong>
            <Longform>  
              Please note, that canceling your subscription on the app or 
              on Google Play does not automatically submit a request for 
              a refund.
            </Longform>
            <Longform>
              Make sure to include the email address that you used to 
              make the subscription and the receipt from the charge.
            </Longform>
            <Longform>
              **Please note, if you previously requested and received 
              a refund, you won’t be eligible for any further refunds.
            </Longform>
            <br/>
            <strong>CONTACT US</strong>
            <Longform>
              Please get in touch with our Customer Support team by sending an email 
              on <a href="mailto://legal@sampld.app" target="_blank">legal@sampld.app</a> for any 
              queries or concerns.
            </Longform>
          </Content>
        )
      default:
        return <div></div>
    }
  }
}

export default LegalPage;