import * as React from "react"
import "./ThemedButton.scss"

export enum ThemedButtonStyle {
    Normal = "themed-button--normal", Outlined = "themed-button--outlined", NormalInverse = "themed-button--normal-inverse"
}
export enum ThemedButtonSize {
    Normal = "themed-button--size-normal", Large = "themed-button--size-large"
}

interface ThemedButtonState {
    label: string
    icon: string
    buttonStyle: ThemedButtonStyle
    size: ThemedButtonSize
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

interface ThemedButtonProps {
    id?: string
    className?: string
    label: string
    icon?: string
    iconAsset?: string
    elevation?: string
    buttonStyle: ThemedButtonStyle
    size: ThemedButtonSize
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export class ThemedButton extends React.Component<ThemedButtonProps, ThemedButtonState> {
    constructor(props: ThemedButtonProps) {
        super(props)
        this.state = {label: props.label, buttonStyle: props.buttonStyle, icon: props.icon, size: props.size,
            onClick: props.onClick}
    }

    render() {
        return (
        <React.Fragment>
            <button id={this.props.id} className={`mdc-button themed-button ${this.state.buttonStyle} ${this.state.size} 
                ${this.props.className}`} onClick={this.props.onClick}>
                <div className="mdc-button__ripple"></div>
                {
                    this.props.icon != null &&
                    <i className="material-icons mdc-button__icon" aria-hidden="true">{this.props.icon}</i>
                }
                {
                    this.props.iconAsset != null &&
                    <img className="material-icons mdc-button__icon" aria-hidden="true" src={this.props.iconAsset} />
                }
                <span className="mdc-button__label">{this.state.label}</span>
            </button>
        </React.Fragment>
        );
    }
}

export default ThemedButton
