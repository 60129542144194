import styled from "styled-components"
import * as React from "react"

const SampleBackgroundDiv = styled.div `
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
`

const SampleBackgroundShade = styled.div `
    position: absolute;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(rgba(0,0,0,.2), transparent);
    z-index: 1;
`

class SampleBackground extends React.Component<SampleBackgroundProps> {
    constructor(props: SampleBackgroundProps) {
        super(props)
    }

    render () {
        return (
            <React.Fragment>
                <SampleBackgroundShade/>
                <SampleBackgroundDiv style={{
                    backgroundColor: this.props.backgroundColorSecondary,
                    backgroundImage: "linear-gradient(45deg, " + this.props.backgroundColorPrimary + "40, transparent)"
                }}/>
            </React.Fragment>
        )
    }
}

interface SampleBackgroundProps {
    backgroundColorPrimary: string
    backgroundColorSecondary: string
}


export default SampleBackground