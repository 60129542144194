export class AssetProvider {
    static getAppLogo() {
        return `/assets/app-logo-black-a5a3a5fa809c45d0d08e9f0b6fb4880097ff747acbc7103b68ce7935b5c3e241.png`;
    }

    static getAppLogoWhite() {
        return `/assets/app-logo-white-bd93cc7315b1c284fd1cccbc5e28f474d69d86c0ab37d6e57a897938d22c3720.png`;
    }

    static getAppleIcon() {
        return `/assets/icons/apple-46945cca2ff579e428348db866b662d5f8b4b28ac02997a336d8c80d2f92b54a.svg`
    }

    static getRegisterImage() {
        return `/assets/register-image-496438f5eb1fcc38f82e4e437731789c99c860e6398d616800edc722a935a033.png`
    }

    static getRegisterImageBackground() {
        return `/assets/backgrounds/register-image-507fdfbdb7c299f2ae11aa2054d471abebe7d0b8eee70c6f152873662695776e.jpg`
    }

    static getLoadingGIF() {
        return `/assets/loading-d8f4c068bbf7891e43dc6156b43d334c7d04faf55104ffabce0a6b2c0873c323.gif`
    }

    static getDownloadSampleBackground() {
        return `/assets/bg-download-sample-e89ace4c6fe91b5a2ef99093fad682fcbc3413e3bcd9045cd7e72d41bbe88037.jpg`
    }

    static getEmailIcon() {
        return `/assets/icons/email-c9f5a9bf2f01b7e80f19f1d31b623f553350f46e4c1cd8995a7bb662b4b7b7c7.svg`
    }

    static getInstagramIcon() {
        return `/assets/icons/instagram-85fbc972ecc37fdb2456265965ced75e843a0fbfdda967516976b3f0efa57cc5.svg`
    }

    static getFacebookIcon() {
        return `/assets/icons/facebook-3ca9c4ae7e47f37d95e8c08f51918c4233cab5628a123f954ef70a1cc0bac6ed.svg`
    }

    static getYouTubeIcon() {
        return `/assets/icons/youtube-065bab9353b3786779592408fa86611d6cac0875a9cbd9ade4714ac97a82a461.svg`
    }

    static getMoodIcon(mood) {
        switch(mood) {
            case "happy":
                return `/assets/icons/moods/smiley.fill-4b42dc745380c0f7261f662f612f6677b19e0dab1aa0ed58bfcf4604d83a0a7f.svg`
            case "calming":
                return `/assets/icons/moods/wind-5493481ffc8b69c42a4ed8b4f78b23d5db81c27d37a721a5951d942ba4fd42b1.svg`
            case "gloomy":
                return `/assets/icons/moods/cloud.rain.fill-4981510d390d3d7ccdc3a1588316191efc2a85b84d5ec08eaa143c1833e75c86.svg`
            case "excited":
                return `/assets/icons/moods/sun.max.fill-e0d69cd4e8f25a3d4fc72592f99daa3703357ef22c773271d30b9c360705ae7c.svg`
            case "mellow":
                return `/assets/icons/moods/moon.fill-fe403148fddca240c3ae0d6490137a3a65c2882c29d0be076d41b9fd69be05b5.svg`
            case "playful":
                return `/assets/icons/moods/hare.fill-54e86da2c7b1bb3da045f0e9a0930f760368ec7142be13d3bed620a2d2d9097b.svg`
            case "romantic":
                return `/assets/icons/moods/heart.fill-fff912687e4728d41b9aecdaeebca776c0b9913ca76344506b19ca642fb89d82.svg`
            case "tense":
                return `/assets/icons/moods/flame.fill-7c7438fffd847b592a76d3e053a30d4f2fe8f1a99f7bf9fc475c067bf09209f6.svg`
            default:
                return ""
        }
    }
}