import * as React from "react";
import styled from "styled-components";
import {AssetProvider} from "./AssetProvider";
import "./MDCComponents.scss";

const DialogWrapper = styled.div ``
const DialogContainer = styled.div ``
const DialogSurface = styled.div `
  flex-direction: row;
  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 991px) and (min-height: 560px) {
    height: 510px;
  }

  @media only screen and (max-width: 991px) and (min-height: 1023px) {
    height: 600px;
  }
`
const DialogSurfaceImage = styled.div `
  background: black;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 991px) {
    flex-basis: 0;
    flex: 1;
  }
`

const DialogSurfaceImageLogo = styled.img `
  width: 60px;

  @media only screen and (max-width: 991px) {
    width: 48px;
  }

  @media only screen and (max-width: 479px) and (orientation: portrait) {
    width: 40px;
  }
`

const DialogSurfaceContent = styled.div `
  display: flex;
  flex-direction: column;
  flex: 3;

  @media only screen and (max-width: 991px) {
    flex-basis: 0;
    flex: 2;
  }
`

const DialogCloseButton = styled.button `
  position: absolute;
  right: 0;
  color: black;

  @media only screen and (max-width: 991px) {
    color: white;
  }

  @media only screen and (max-width: 991px) and (orientation: portrait) {
    right: 8px;
  }
`

const DialogTitle = styled.h2 `
  display: flex;
  font-weight: 600;
  font-size: 32px;
  padding-top: 3rem;
  padding-bottom: 14px;

  @media only screen and (max-width: 991px) and (orientation: portrait) {
    font-size: 32px;
    padding: 32px 16px 0;
    align-items: center;
  }

  @media only screen and (max-width: 479px) and (orientation: portrait) {
    font-size: 24px;
    padding: 24px 8px 0;
  }
`

const DialogTitleSpan = styled.span ``
const DialogContent = styled.div `
  display: flex;
  flex-direction: column;
  
  @media only screen and (max-width: 479px) and (orientation: portrait) {
    font-size: 14px;
    line-height: normal;
    padding: 0 8px 8px;
  }
`

const DialogButton = styled.button `
  margin: 0 24px 24px;

  @media only screen and (max-width: 991px) and (orientation: portrait) {
    min-width: 180px;
  }

  @media only screen and (max-width: 479px) and (orientation: portrait) {
    margin: 0 8px 16px;
  }
`

const ButtonRipple = styled.div ``
const ButtonLabel = styled.div ``

const Loading = styled.img`
  width: 30px;
  margin: auto;
  display: none;

  @media only screen and (max-width: 991px) and (orientation: portrait) {
    width: 64px;
  }
  
  @media only screen and (max-width: 479px) and (orientation: portrait) {
    width: 24px;
  }
`

const DialogScrim = styled.div ``

interface ModalDialogProps {
  id?: string
  dialogClass?: string
  title: string
  titleID?: string
  backgroundImageURL?: string
  logoURL: string
  labelledBy?: string
  describedBy?: string
  dataMdcDialogAction?: string
  buttonLabel: string
  withLoading: boolean
}

class ModalDialog extends React.Component<ModalDialogProps> {
  render () {
    return (
      <React.Fragment>
        <DialogWrapper className={"mdc-dialog mdc-dialog--modal " + this.props.dialogClass} id={this.props.id}>
          <DialogContainer className="mdc-dialog__container">
            <DialogSurface className="mdc-dialog__surface" role="alertdialog" aria-modal="true" 
            aria-labelledby={this.props.labelledBy != null ? this.props.labelledBy : "mdc-dialog"} 
            aria-describedby={this.props.labelledBy != null ? this.props.labelledBy : "mdc-dialog"}>
              <DialogSurfaceImage className="mdc-dialog__surface__image" style={this.props.backgroundImageURL != null && {
                background: `url(${this.props.backgroundImageURL})`, 
                backgroundSize: "cover"
                }}>
                  <DialogSurfaceImageLogo src={this.props.logoURL}/>
              </DialogSurfaceImage>
            
              <DialogSurfaceContent>
                <DialogTitle className="mdc-dialog__title" id={this.props.titleID}>
                  <DialogTitleSpan>{this.props.title}</DialogTitleSpan>
                </DialogTitle>

                <DialogContent className="mdc-dialog__content">
                  {this.props.children}  
                </DialogContent>

                <DialogButton className="mdc-button mdc-button--black mdc-button--submit" data-mdc-dialog-action={this.props.dataMdcDialogAction}>
                  <ButtonRipple className="mdc-button__ripple"/>
                  <ButtonLabel className="mdc-button__label">{this.props.buttonLabel}</ButtonLabel>
                </DialogButton>

                {this.props.withLoading == true &&
                <Loading src={AssetProvider.getLoadingGIF()} className="mdc-dialog__loading"/>
                }
              </DialogSurfaceContent>
              <DialogCloseButton className="mdc-dialog__close mdc-icon-button material-icons">close</DialogCloseButton>
            </DialogSurface>
          </DialogContainer>
          <DialogScrim className="mdc-dialog__scrim"/>
        </DialogWrapper>
      </React.Fragment>
    );
  }
}

export default ModalDialog
