import {AssetProvider as AssetProviderImpl} from "./AssetProvider.js.erb"

export class AssetProvider {
    static getAppLogo() {
        return AssetProviderImpl.getAppLogo()
    }

    static getAppLogoWhite() {
        return AssetProviderImpl.getAppLogoWhite()
    }

    static getAppleIcon() {
        return AssetProviderImpl.getAppleIcon()
    }

    static getRegisterImage() {
        return AssetProviderImpl.getRegisterImage()
    }

    static getRegisterImageBackground() {
        return AssetProviderImpl.getRegisterImageBackground()
    }

    static getLoadingGIF() {
        return AssetProviderImpl.getLoadingGIF()
    }

    static getDownloadSampleBackground() {
        return AssetProviderImpl.getDownloadSampleBackground()
    }

    static getEmailIcon() {
        return AssetProviderImpl.getEmailIcon()
    }

    static getInstagramIcon() {
        return AssetProviderImpl.getInstagramIcon()
    }

    static getFacebookIcon() {
        return AssetProviderImpl.getFacebookIcon()
    }

    static getYouTubeIcon() {
        return AssetProviderImpl.getYouTubeIcon()
    }

    static getMoodIcon(mood) {
        return AssetProviderImpl.getMoodIcon(mood.toLowerCase())
    }
}