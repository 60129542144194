import * as React from "react"

interface ActionLinkProps {
  className?: string
  destination: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

export class ActionLink extends React.Component<ActionLinkProps> {
  render() {
    return (
      <a className={this.props.className} href={this.props.destination} onClick={this.props.onClick}>
        {this.props.children}
      </a>
    );
  }
}