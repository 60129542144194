import * as React from "react";
import styled from "styled-components";

const DialogSubmitButton = styled.button`
    text-transform: none;
    letter-spacing: normal;
    min-width: 96px;

    @media only screen and (max-width: 991px) and (orientation: portrait) {
        min-width: 180px;
    }
`
const Dialog =  styled.div``
const DialogContainer = styled.div``
const DialogTitle = styled.h2``
const DialogContent = styled.div``
const DialogActions = styled.div``
const DialogSurface = styled.div``
const ButtonRipple = styled.div``
const ButtonLabel = styled.span``
const DialogScrim = styled.div``

interface PopupDialogProps {
  type?: string
  describedBy?: string
  labelledBy?: string
  title: string
  content: string
  buttonLabel?: string
}

class PopupDialog extends React.Component<PopupDialogProps> {
  render () {
    return (
      <React.Fragment>
        <Dialog id={this.props.type != null ? this.props.type + "-dialog" : "dialog"} className="mdc-dialog">
          <DialogContainer className="mdc-dialog__container">
              <DialogSurface className="mdc-dialog__surface" role="alertdialog" aria-modal="true" aria-labelledby={this.props.labelledBy != null ? this.props.labelledBy : "my-dialog"} 
                aria-describedby={this.props.describedBy != null ? this.props.describedBy : "my-dialog"}>
                  <DialogTitle className="mdc-dialog__title">{this.props.title}</DialogTitle>
                  <DialogContent className="mdc-dialog__content">{this.props.content}</DialogContent>
                  <DialogActions className="mdc-dialog__actions">
                      <DialogSubmitButton className="mdc-button mdc-button--submit" data-mdc-dialog-action="done">
                          <ButtonRipple className="mdc-button__ripple"/>
                          <ButtonLabel className="mdc-button__label">{this.props.buttonLabel != null ? this.props.buttonLabel : "OK"}</ButtonLabel>
                      </DialogSubmitButton>
                  </DialogActions>
              </DialogSurface>
          </DialogContainer>
          <DialogScrim className="mdc-dialog__scrim"/>
      </Dialog>
      </React.Fragment>
    );
  }
}

export default PopupDialog
